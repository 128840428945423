import * as yup from 'yup'
import i18n from '../i18n'

import {
  ALL_EXCEPT_CYRILLIC_REGEXP,
  COMPANY_NAME_REGEX,
  LATINICA_ONLY_REGEXP,
  MAX_BUSINESS_LENGTH,
  MIN_LENGTH,
} from '../constants'

export const PartnerhipSchema = yup.object().shape({
  fullName: yup
    .string()
    .required(i18n.t('Validation-P1', { min: MIN_LENGTH }))
    .matches(LATINICA_ONLY_REGEXP, i18n.t('Validation-P2', { min: MIN_LENGTH }))
    .test(
      'minLength',
      i18n.t('Validation-P2', { min: MIN_LENGTH }),
      (val) => !!val && val?.length >= MIN_LENGTH,
    ),
  email: yup.string().required(i18n.t('Validation-P4')).email(i18n.t('Validation-P5')),
  companyName: yup
    .string()
    .required(i18n.t('Validation-P3'))
    .matches(COMPANY_NAME_REGEX, i18n.t('Validation-P7')),
  country: yup.string().nullable().required(i18n.t('Validation-P15', 'Country is required')),
})
