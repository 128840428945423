import {
  Box,
  Button,
  CardMedia,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core'
import React, { FC, useCallback, useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import imgHero2x from '../../assets/images/img-non-swiss@2x.png'

const cookies = new Cookies()

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.drawer + 2,
    background: 'rgba(255, 255, 255, 0.6)',
    margin: '0 auto',
  },
  container: {
    maxHeight: '800px',
    width: '80%',
    maxWidth: '1000px',
    background: '#fff',
    color: '#000',
    flexFlow: 'column',
    alignSelf: 'center',
    position: 'relative',
    margin: '3% auto',
    padding: theme.spacing(6),
    border: '1px #fafafa solid',
    filter: 'drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.3))',
    overflow: 'auto',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '85%',
      height: '90%',
      paddingBottom: theme.spacing(5),
    },
  },
  row: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  consentButton: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  text: {
    fontSize: '0.9em',
  },
}))

export const CrossBorderServicesCheckerModal: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [state, setState] = useState({
    country: '',
    bot: false,
  })

  const buttonHandler = useCallback(() => {
    cookies.set('crossBorderConsent', 'true', { path: '/', maxAge: 86400000 })
    window.location.reload()
  }, [cookies.get('crossBorderConsent')])

  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const { country } = response.data
        setState({
          ...state,
          country,
        })
      })
      .catch((error) => {
        setState({
          ...state,
          country: 'show',
        })
      })
  }

  const isBot = () => {
    // check if it's a bot from the user agent token
    const botPattern =
      '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Google-InspectionTool|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)'
    const regex = new RegExp(botPattern, 'i')
    const userAgent = navigator.userAgent
    if (regex.test(userAgent)) {
      return true
    }
    return false
  }

  const bot = isBot()

  useEffect(() => {
    getGeoInfo()
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
    })
  }, [])

  const theme = useTheme<Theme>()

  const showModal =
    !bot && state.country !== '' && state.country !== 'CH' && !cookies.get('crossBorderConsent')

  if (showModal) {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
    const body = document.body
    body.style.position = 'fixed'
    body.style.top = `0`
  } else {
    const body = document.body
    const scrollY = body.style.top
    body.style.position = ''
    body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }

  return showModal ? (
    <Box className={classes.overlay}>
      <Box m={6} className={classes.container}>
        <Box className={classes.row}>
          <Grid container>
            <Hidden smDown>
              <Grid item xs={4}></Grid>
            </Hidden>
            <Grid item xs={12} md={4}>
              <CardMedia component="img" alt="" height="100%" image={imgHero2x} />
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <Typography variant={'h5'}>
            <b>{t('COMMON-CrossBorderServices-Title')}</b>
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography className={classes.text}>
            <div dangerouslySetInnerHTML={{ __html: t('COMMON-CrossBorderServices-Text') }} />
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant={'h5'}>
            <b>{t('COMMON-CrossBorderServices-Subtitle')}</b>
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography className={classes.text}>
            <div dangerouslySetInnerHTML={{ __html: t('COMMON-CrossBorderServices-Text-2') }} />
          </Typography>
        </Box>
        <Box>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => buttonHandler()}
            className={classes.consentButton}
          >
            {t('COMMON-CrossBorderServices-CTA')}
          </Button>
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  )
}
