import React, { FC, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'

import SentIcon from '../../assets/icons/icon-message-sent.svg?react'
import cityContactUs from '../../assets/images/img-message-sent-bottom@2x.png'
import { tagManagerHandler } from '../../utils'
import { PATH_ROOT } from '../../constants'
import { Hidden } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  row: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  extraMargin: {
    marginTop: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
    '& > div': {
      marginTop: theme.spacing(2),
    },
  },
  pageTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
      fontSize: '2.25rem',
    },
  },
  heroImage: {
    padding: theme.spacing(0, 3),
  },
}))

export const MessageSent: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  let timerId: NodeJS.Timeout

  tagManagerHandler('PageMessageSent')

  useEffect(() => {
    timerId = setTimeout(() => {
      history.push(PATH_ROOT)
    }, 15000)
    return () => {
      clearTimeout(timerId)
    }
  }, [])

  const buttonHandler = useCallback(() => {
    clearTimeout(timerId)
  }, [history])

  return (
    <Box>
      <Box style={{ textAlign: 'center' }}>
        <SentIcon />
      </Box>
      <Container maxWidth="lg">
        <Box className={classes.row}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              className={classes.pageTitle}
            >
              {t('MessageSent-H1')}
            </Typography>
          </Grid>

          <Grid item xs={12} justify={'center'}>
            <Hidden smDown>
              <Typography align="center">{t('MessageSent-P11')}</Typography>
              <Typography align="center">
                <Link onClick={() => buttonHandler()} style={{ cursor: 'pointer' }}>
                  <u>{t('MessageSent-P12')}</u>
                </Link>{' '}
                {t('MessageSent-P13')}
              </Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography align="center">
                {t('MessageSent-P11')}
                &nbsp;
                <Link onClick={() => buttonHandler()} style={{ cursor: 'pointer' }}>
                  <u>{t('MessageSent-P12')}</u>
                </Link>{' '}
                {t('MessageSent-P13')}
              </Typography>
            </Hidden>
          </Grid>
        </Box>
      </Container>

      <Box className={clsx(classes.row, classes.extraMargin, classes.mrgbtm)}>
        <Box mt={8}>
          <CardMedia
            component="img"
            alt=""
            height="100%"
            className={classes.heroImage}
            image={cityContactUs}
          />
        </Box>
      </Box>
    </Box>
  )
}
