import * as React from 'react'
import ReactCountryFlag from 'react-country-flag'
import An from './customFlags/images/4x3/an.svg?react'

export const FlagIcon: React.FC<{ code: string; size?: string; className?: string }> = ({
  code,
  size,
  className,
}) => {
  if (code === 'an') {
    return <An style={{ width: '1.5em', height: 'auto' }} />
  }
  return (
    <ReactCountryFlag
      className={className}
      countryCode={code}
      svg
      style={{
        fontSize: size ? size : '1.5em',
      }}
    />
  )
}
