import { FC, default as React, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CardMedia from '@material-ui/core/CardMedia'
import MultiCurrencyAccount from '../../assets/icons/icon-multi-currency.svg?react'
import AcceptCard from '../../assets/icons/icon-cards.svg?react'
import CompanyName from '../../assets/icons/icon-unique-iban.svg?react'
import UberTransparent from '../../assets/icons/icon-fees.svg?react'
import MarketPlaces from '../../assets/icons/icon-marketplace.svg?react'
import GlobalEcommerce from '../../assets/icons/icon-ecommerce.svg?react'
import AffilateNetwork from '../../assets/icons/icon-networks.svg?react'
import MarketingInfl from '../../assets/icons/icon-user.svg?react'
import FinTechSps from '../../assets/icons/icon-fintech.svg?react'
import IconArrow from '../../assets/icons/icon-arrow.svg?react'
import imgHero2x from '../../assets/images/img-laptop@2x.png'
import iPhone2x from '../../assets/images/img-phone@2x.png'
import city from '../../assets/images/img-switzerland-1@2x.jpg'
import sponsorship from '../../assets/images/img-banner-sponsorship@2x.png'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { Hidden, MenuItem, MenuList } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { PATH_TALKTOUS } from '../../constants'
import {
  PATH_AFFILIATE_NETWORKS,
  PATH_ECOMMERCE,
  PATH_FINTECHS,
  PATH_MARKET_PLACES,
  PATH_MEDIA_INFLUENCERS,
} from '../../constants'
import { useHistory } from 'react-router'
import { tagManagerHandler } from '../../utils'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContentPref: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  iPhoneimg: {
    display: 'inline-block',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  readMoreButton: {
    width: 216,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  footerNavColumns: {
    justifyContent: 'space-between',
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  footerNavColumnList: {
    display: 'block',

    MuiListItemButton: {
      padding: 0,
    },
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  subtitleBoxIcon: {
    body1: {
      fontSize: '1.125rem',
      fontWeight: 'normal',
    },
  },

  menuItem: {
    flexWrap: 'wrap',
    padding: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
    },

    '& .MuiMenuItem-root': {
      width: '50%',
      padding: theme.spacing(2.5),
      justifyContent: 'center',
      whiteSpace: 'normal',
    },

    '& .MuiListItem-button:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },

    '& .img:hover': { backgroundColor: theme.palette.secondary.main },

    '& .menuItemInner': {
      display: 'flex',
      alignItems: 'top',
      paddingTop: theme.spacing(0),
      borderTop: '8px solid transparent',
      transition: 'border-top-color 0.2s, font-weight 0.2s',
      position: 'relative',
      top: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',

      '& .MuiListItemIcon-root': {
        opacity: 1,
      },
    },
    '&:hover .menuItemInner': {
      backgroundColor: 'green',
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      background: theme.palette.secondary.main,
      '& .menuItemInner': {
        background: theme.palette.secondary.main,
      },
      '& .MuiListItemIcon-root': {
        opacity: 1,
        // color: theme.palette.secondary.main,
      },
      '& .MuiTypography-body1': {},
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  minW: {
    minWidth: '250px',
  },
  imgWrap: {
    textAlign: 'center',
  },
  row: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  pageTitle: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.25rem',
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  features: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export const HomePage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  const routerHandler = useCallback((path) => {
    history.push(path)
  }, [])

  tagManagerHandler('PageHome')

  return (
    <>
      <Container>
        <Box>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={classes.pageTitle}
            >
              {t('Home-H1')}
            </Typography>
            <Typography variant="subtitle1" align="center" component={'div'} gutterBottom>
              {t('Home-P11')}
            </Typography>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container>
            <Hidden smDown>
              <Grid item xs={1}></Grid>
            </Hidden>
            <Grid item xs={12} md={10}>
              <CardMedia component="img" alt="" height="100%" image={imgHero2x} />
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container spacing={2} className={classes.features}>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('Home-H2')}
              </Typography>
              <Typography>{t('Home-P21')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('Home-H3')}
              </Typography>
              <Typography>
                <div dangerouslySetInnerHTML={{ __html: t('Home-P31') }} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('Home-H4')}
              </Typography>
              <Typography>{t('Home-P41')}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container>
            <Grid item xs={12}>
              <a
                href="https://www.klarpay.com/news/klarpay-renews-commitment-to-swiss-youth-olympic-sailing"
                target="_blank"
                rel="noreferrer"
              >
                <CardMedia component="img" alt="" height="100%" image={sponsorship} />
              </a>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row /*, classes.extraMargin*/)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} className={classes.rowText}>
              <Typography variant={'h2'} className={classes.rowTitle}>
                {t('Home-H5')}
                <br /> {t('Home-H51')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>
                {t('Home-P51')}
                <br />
                {t('Home-P52')}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <MenuList className={classes.menuItem}>
                  <MenuItem>
                    <Box component={'div'} width={'200px'}>
                      <Box display={'flex'} justifyContent={'center'}>
                        <MultiCurrencyAccount />
                      </Box>

                      <Box padding={'22px'}>
                        <Typography className={classes.subtitleBoxIcon} align={'center'}>
                          {t('Home-H6')}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>

                  <MenuItem>
                    <Box component={'div'} width={'200px'}>
                      <Box display={'flex'} justifyContent={'center'}>
                        <AcceptCard />
                      </Box>

                      <Box padding={'22px'}>
                        <Typography className={classes.subtitleBoxIcon} align={'center'}>
                          {t('Home-H7')}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                  <MenuItem>
                    <Box component={'div'} width={'200px'}>
                      <Box display={'flex'} justifyContent={'center'}>
                        <CompanyName />
                      </Box>

                      <Box padding={'22px'}>
                        <Typography className={classes.subtitleBoxIcon} align={'center'}>
                          {t('Home-H8')}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                  <MenuItem>
                    <Box component={'div'} width={'200px'}>
                      <Box display={'flex'} justifyContent={'center'}>
                        <UberTransparent />
                      </Box>

                      <Box padding={'22px'}>
                        <Typography className={classes.subtitleBoxIcon} align={'center'}>
                          {t('Home-H9')}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                </MenuList>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container spacing={8}>
            <Grid className={classes.imgWrap} item xs={12} sm={6}>
              <CardMedia
                component="img"
                alt=""
                height="100%"
                className={classes.iPhoneimg}
                image={iPhone2x}
              />
            </Grid>

            <Grid item xs={12} sm={6} className={classes.rowText}>
              <Typography variant={'h2'} className={classes.rowTitle}>
                {t('Home-H10')}
              </Typography>

              <Typography className={classes.heroContentPrefHeader}>{t('Home-P10')}</Typography>

              {/*<Button*/}
              {/*  className={classes.readMoreButton}*/}
              {/*  size="medium"*/}
              {/*  variant="contained"*/}
              {/*  endIcon={<IconArrow />}*/}
              {/*>*/}
              {/*  Learn more*/}
              {/*</Button>*/}
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container spacing={8}>
            <Grid item xs={12} md={6} className={classes.rowText}>
              <Typography variant={'h2'} className={classes.rowTitle}>
                {t('Home-H11')}
              </Typography>
              <br />
              <Typography>{t('Home-P111')}</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <MenuList className={classes.menuItem}>
                  <MenuItem onClick={() => routerHandler(PATH_MARKET_PLACES)}>
                    <Box component={'div'} width={'200px'}>
                      <Box display={'flex'} justifyContent={'center'}>
                        <MarketPlaces />
                      </Box>

                      <Box padding={'22px'}>
                        <Typography className={classes.subtitleBoxIcon} align={'center'}>
                          {t('Home-H12')}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>

                  <MenuItem onClick={() => routerHandler(PATH_ECOMMERCE)}>
                    <Box component={'div'} width={'200px'}>
                      <Box display={'flex'} justifyContent={'center'}>
                        <GlobalEcommerce />
                      </Box>

                      <Box padding={'22px'}>
                        <Typography className={classes.subtitleBoxIcon} align={'center'}>
                          {t('Home-H13')}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>

                  <MenuItem onClick={() => routerHandler(PATH_AFFILIATE_NETWORKS)}>
                    <Box component={'div'} width={'200px'}>
                      <Box display={'flex'} justifyContent={'center'}>
                        <AffilateNetwork />
                      </Box>

                      <Box padding={'22px'}>
                        <Typography className={classes.subtitleBoxIcon} align={'center'}>
                          {t('Home-H14')}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>

                  <MenuItem onClick={() => routerHandler(PATH_MEDIA_INFLUENCERS)}>
                    <Box component={'div'} width={'200px'}>
                      <Box display={'flex'} justifyContent={'center'}>
                        <MarketingInfl />
                      </Box>

                      <Box padding={'22px'}>
                        <Typography className={classes.subtitleBoxIcon} align={'center'}>
                          {t('Home-H15')}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>

                  <MenuItem onClick={() => routerHandler(PATH_FINTECHS)}>
                    <Box component={'div'} width={'200px'}>
                      <Box display={'flex'} justifyContent={'center'}>
                        <FinTechSps />
                      </Box>

                      <Box padding={'22px'}>
                        <Typography className={classes.subtitleBoxIcon} align={'center'}>
                          {t('Home-H16')}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                </MenuList>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Box className={clsx(classes.row, classes.extraMargin)}>
        <Box mt={8}>
          <CardMedia component="img" alt="" height="100%" image={city} />
        </Box>
      </Box>

      <Container>
        <Box className={clsx(classes.row, classes.extraMargin, classes.mrgbtm)}>
          <Grid container direction={'column'} alignItems="center">
            <Grid item xs={12} md={10}>
              <Typography
                variant={'h2'}
                align="center"
                color="textPrimary"
                gutterBottom
                className={classes.rowTitle}
              >
                {t('Home-H17')}
              </Typography>
            </Grid>

            <Box mt={8} className={classes.buttonContainer}>
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                endIcon={<IconArrow />}
                onClick={() => buttonHandler(PATH_TALKTOUS)}
                className={classes.getStartedButton}
              >
                {t('COMMON-CTA-Consultation')}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
