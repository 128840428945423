import React, { FC, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import houseWithFlags from '../../assets/images/img-switzerland-6@2x.jpg'
import oldPhoto from '../../assets/images/img-board-of-directors@2x.jpg'
//import pdfBook from '../../assets/images/pdfBook.png'
import pdfBook from '../../assets/images/img-pdf.png'

import oldStreet from '../../assets/images/img-switzerland-5@2x.jpg'
import teamMeeting from '../../assets/images/img-executive-management@2x.jpg'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Hidden } from '@material-ui/core'
import clsx from 'clsx'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { PATH_BOARD_DIRECTORS, PATH_EXECUTIVE_MANAGEMENT } from '../../constants'
import IconArrow from '../../assets/icons/icon-arrow.svg?react'
import { tagManagerHandler } from '../../utils'

const useStyles = makeStyles((theme) => ({
  heroImage: {
    maxWidth: '100%',
    backgroundRepeat: 'no-Repeat',
    backgroundPosition: 'center',
    color: '#fff',
    position: 'relative',
    backgroundSize: 'cover',
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
  },
  readMoreButton: {
    width: 216,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  grayBg: {
    backgroundColor: '#f8f8f8',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 4),
      '& .MuiCardMedia-img': {
        width: '50%',
        display: 'inline-block',
      },
    },
  },
  row: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  pageTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
      fontSize: '2.25rem',
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  item2: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
}))

export const AboutPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  const pdfHandler = useCallback(() => {
    window.open('/pdf/klarpay.pdf')
  }, [])

  tagManagerHandler('PageAbout')

  return (
    <>
      <Container>
        <Grid item xs={12}>
          <Box className={classes.row}>
            <Grid item xs={12} container justify={'center'}>
              <Typography
                component="h1"
                variant={'h1'}
                align="center"
                color="textPrimary"
                className={classes.pageTitle}
              >
                {t('About-Klarpay')}
              </Typography>
            </Grid>
          </Box>

          <Box className={classes.row}>
            <CardMedia
              component="img"
              alt=""
              height="100%"
              className={classes.heroImage}
              image={houseWithFlags}
            />
          </Box>

          <Box className={classes.row}>
            <Grid container spacing={2} justify={'center'}>
              <Grid item xs={10}>
                <Typography paragraph={true} text-align={'justify'}>
                  {t('About-Klarpay-P1')}
                </Typography>
                <Typography paragraph={true} text-align={'justify'}>
                  {t('About-Klarpay-P2')}
                </Typography>
                <Typography paragraph={true} text-align={'justify'}>
                  {t('About-Klarpay-P3')}
                </Typography>
                <Typography paragraph={true} text-align={'justify'}>
                  {t('About-Klarpay-P4')}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={clsx(classes.grayBg, classes.row)}>
            <Grid container spacing={8}>
              <Hidden smDown>
                <Grid item xs={1} />
              </Hidden>

              <Grid item xs={12} md={4}>
                <Box textAlign="center">
                  <CardMedia component="img" alt="" image={pdfBook} onClick={pdfHandler} />
                </Box>
              </Grid>

              <Hidden smDown>
                <Grid item xs={1} />
              </Hidden>

              <Grid item xs={12} md={5}>
                <Typography
                  variant={'h2'}
                  paragraph={true}
                  text-align={'justify'}
                  className={clsx('txtBlock', classes.rowTitle)}
                >
                  {t('About-Governance')}
                </Typography>
                <Typography paragraph={true} text-align={'justify'}>
                  {t('About-Governance-P1')}
                </Typography>
                <Typography paragraph={true} text-align={'justify'}>
                  {t('About-Governance-P2')}
                </Typography>
                <Button
                  className={classes.readMoreButton}
                  size="medium"
                  variant="contained"
                  onClick={pdfHandler}
                  endIcon={<IconArrow />}
                >
                  {t('About-Governance-More')}
                </Button>
              </Grid>
              <Hidden smDown>
                <Grid item xs={1} />
              </Hidden>
            </Grid>
          </Box>

          <Box className={clsx(classes.row, classes.extraMargin)}>
            <Grid container spacing={2}>
              <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
                <Typography variant={'h2'} className={classes.rowTitle}>
                  {t('About-Directors')}
                </Typography>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('About-Directors-P1')}
                </Typography>
                <Button
                  className={classes.readMoreButton}
                  size="medium"
                  variant="contained"
                  onClick={() => buttonHandler(PATH_BOARD_DIRECTORS)}
                  endIcon={<IconArrow />}
                >
                  {t('About-Directors-Meet')}
                </Button>
              </Grid>

              <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
                <Grid item xs={12} md={10}>
                  <CardMedia component="img" alt="" height="100%" image={oldPhoto} />
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box className={clsx(classes.row, classes.extraMargin)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} container>
                <Grid item xs={12} md={10}>
                  <CardMedia component="img" alt="" height="100%" image={teamMeeting} />
                </Grid>
              </Grid>

              <Grid item xs={12} md={6} className={classes.rowText}>
                <Typography variant={'h2'} className={classes.rowTitle}>
                  {t('About-Management')}
                </Typography>
                <Typography className={classes.heroContentPrefHeader}>
                  {t('About-Management-P1')}
                </Typography>
                <Button
                  className={classes.readMoreButton}
                  size="medium"
                  variant="contained"
                  onClick={() => buttonHandler(PATH_EXECUTIVE_MANAGEMENT)}
                  endIcon={<IconArrow />}
                >
                  {t('About-Management-Meet')}
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box className={clsx(classes.row, classes.extraMargin)}>
            <Grid container spacing={8} justify={'center'}>
              <Grid item xs={10}>
                <Typography
                  variant={'h2'}
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  className={classes.rowTitle}
                >
                  {t('About-Klarpay-AG')}
                </Typography>
                <Typography variant={'subtitle1'} paragraph={true} align="left" gutterBottom>
                  {t('About-Klarpay-AG-P1')}
                </Typography>
                <Typography variant={'subtitle1'} paragraph={true} text-align={'justify'}>
                  {t('About-Klarpay-AG-P2')}
                </Typography>
                <Typography variant={'subtitle1'} paragraph={true} text-align={'justify'}>
                  {t('About-Klarpay-AG-P3')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Container>

      <Box className={clsx(classes.row, classes.extraMargin, classes.mrgbtm)}>
        <Box mt={8}>
          <CardMedia
            component="img"
            alt=""
            height="100%"
            className={classes.heroImage}
            image={oldStreet}
          />
        </Box>
      </Box>
    </>
  )
}
