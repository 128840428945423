import React, { FC, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import water from '../../assets/images/img-switzerland-3@2x.jpg'
import money from '../../assets/images/img-streamline@2x.jpg'
import phone from '../../assets/images/img-phone@2x.png'
import hands from '../../assets/images/img-better-under-one-roof@2x.jpg'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import IconArrow from '../../assets/icons/icon-arrow.svg?react'
import { PATH_TALKTOUS } from '../../constants'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { tagManagerHandler } from '../../utils'

const useStyles = makeStyles((theme) => ({
  heroImage: {
    maxWidth: '100%',
    backgroundRepeat: 'no-Repeat',
    backgroundPosition: 'center',
    color: '#fff',
    position: 'relative',
    backgroundSize: 'cover',
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
  },
  readMoreButton: {
    width: 216,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  row: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  pageTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
      fontSize: '2.25rem',
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  item2: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export const AffiliateNetworkPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  tagManagerHandler('PageAffiliateNetwork')

  return (
    <>
      <Container>
        <Box className={classes.row}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              className={classes.pageTitle}
            >
              {t('Affiliates')}
            </Typography>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} container>
              <Grid item xs={12} md={10}>
                <CardMedia component="img" alt="" height="100%" image={money} />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} className={classes.rowText}>
              <Typography variant={'h2'} className={classes.rowTitle}>
                {t('Affiliates-H1')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>
                {t('Affiliates-P11')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>
                {t('Affiliates-P12')}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.extraMargin)}>
          <Grid container spacing={2}>
            <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
              <Typography variant={'h2'} className={classes.rowTitle}>
                {t('Affiliates-H2')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>
                {t('Affiliates-P21')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>
                {t('Affiliates-P22')}
              </Typography>
            </Grid>

            <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
              <Grid item xs={12} md={10}>
                <CardMedia component="img" alt="" height="100%" image={phone} />
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.extraMargin)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} container>
              <Grid item xs={12} md={10}>
                <CardMedia component="img" alt="" height="100%" image={hands} />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} className={classes.rowText}>
              <Typography variant={'h2'} className={classes.rowTitle}>
                {t('Affiliates-H3')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>
                {t('Affiliates-P31')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>
                {t('Affiliates-P32')}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.extraMargin)}>
          <Grid container spacing={8} justify={'center'}>
            <Grid item xs={10}>
              <Typography
                variant={'h2'}
                align="left"
                color="textPrimary"
                gutterBottom
                className={classes.rowTitle}
              >
                {t('Affiliates-H4')}
              </Typography>
              <Typography variant={'subtitle1'} align="left" gutterBottom>
                {t('Affiliates-P41')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Box className={clsx(classes.row, classes.extraMargin, classes.mrgbtm)}>
        <Box mt={8}>
          <CardMedia
            component="img"
            alt=""
            height="100%"
            className={classes.heroImage}
            image={water}
          />
        </Box>
      </Box>

      <Container>
        <Box className={clsx(classes.row, classes.extraMargin, classes.mrgbtm)}>
          <Grid container direction={'column'} alignItems="center">
            <Grid item xs={12} md={10}>
              <Typography
                variant={'h2'}
                align="center"
                color="textPrimary"
                gutterBottom
                className={classes.rowTitle}
              >
                {t('COMMON-CTA-Title')}
              </Typography>
            </Grid>

            <Box mt={8} className={classes.buttonContainer}>
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                className={classes.getStartedButton}
                endIcon={<IconArrow />}
                onClick={() => buttonHandler(PATH_TALKTOUS)}
              >
                {t('COMMON-CTA-Consultation')}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
