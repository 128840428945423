import React, { FC, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import { useHistory } from 'react-router'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import logo from '../../assets/images/logo.svg'
import {
  PATH_ABOUT,
  PATH_USE_CASES,
  PATH_ROOT,
  PATH_TALKTOUS,
  PATH_APPLE_PAY,
} from '../../constants'

import { Hidden, Link } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import imgNotFound from '../../assets/images/img-not-found.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  img: {
    width: 'auto',
  },
  accountButtons: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .MuiButton-root': {
      minWidth: 128,
      minHeight: 36,
      lineHeight: '24px',
      boxShadow: 'none',
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        marginLeft: 32,
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: 64,
      },
      [theme.breakpoints.down('sm')]: {
        fontWeight: 600,
      },
    },
  },
  appBar: {
    boxShadow: '0px 0.5px 4px rgba(0, 0, 0, 0.15)',
  },
  customizeToolbar: {
    width: '100%',
    minHeight: 64,
    padding: theme.spacing(0, 2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    flexShrink: 0,
    width: 155,
    height: 26,
    marginRight: 20,
  },
  logoImg: {
    width: '100%',
    height: '100%',
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
  },
  navItem: {
    whiteSpace: 'nowrap',
  },
  mobileHeaderTop: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  point: {
    cursor: 'pointer',
  },
  row: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },

  addRow: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },

  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.25rem',
    },
  },
  allNav: {
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'baseline',
    [theme.breakpoints.down('sm')]: {
      order: 1,
      flexDirection: 'column',
      alignItems: 'normal',
    },
    '& .MuiLink-button': {
      fontSize: '1rem',
      lineHeight: '24px',
      fontWeight: 'normal',
      opacity: 1,
      '&:hover': {
        opacity: 0.8,
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2, 0),
      },

      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
}))

export const NotFoundPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )
  return (
    <React.Fragment>
      <Grid container justify={'center'}>
        <Grid item xs={11} container justify={'center'}>
          <Toolbar className={classes.customizeToolbar}>
            <Box className={classes.logo}>
              <img
                src={logo}
                alt={'Klarpay AG'}
                className={classes.point}
                onClick={() => buttonHandler(PATH_ROOT)}
              />
            </Box>

            <Hidden smDown>
              <Box display="flex" alignItems="center" />
            </Hidden>
          </Toolbar>
        </Grid>
      </Grid>

      <Container>
        <Box className={classes.row}>
          <Grid container>
            <Hidden smDown>
              <Grid item xs={1} />
            </Hidden>

            <Grid item xs={12} md={10}>
              <Box display={'flex'} justifyContent={'center'}>
                <CardMedia component="img" alt="" image={imgNotFound} className={classes.img} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.row}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={classes.pageTitle}
            >
              {t('NotFound')}
            </Typography>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              paragraph={true}
              variant="subtitle1"
              align="center"
              component={'div'}
              gutterBottom
            >
              {t('NotFound-P1')}
            </Typography>
          </Grid>
        </Box>

        <Box className={classes.addRow}>
          <Grid item xs={12} container justify={'center'}>
            <Typography component={'div'} paragraph={true} variant="subtitle1" align="center">
              {t('NotFound-P2')}
            </Typography>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid item xs={12} container justify={'center'} className={classes.allNav}>
            <Link component="button" color="inherit" onClick={() => buttonHandler(PATH_ROOT)}>
              {t('NotFound-P3')}
            </Link>
            <Link component="button" color="inherit" onClick={() => buttonHandler(PATH_USE_CASES)}>
              {t('NotFound-P4')}
            </Link>
            <Link component="button" color="inherit" onClick={() => buttonHandler(PATH_APPLE_PAY)}>
              {t('NotFound-P7')}
            </Link>
            <Link component="button" color="inherit" onClick={() => buttonHandler(PATH_ABOUT)}>
              {t('NotFound-P5')}
            </Link>
            <Link component="button" color="inherit" onClick={() => buttonHandler(PATH_TALKTOUS)}>
              {t('NotFound-P6')}
            </Link>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  )
}
