import React, { FC, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import newsPages from './newsPages'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import clsx from 'clsx'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { tagManagerHandler } from '../../utils'
import { PATH_NEWS_AND_MEDIA } from '../../constants'
import IconButton from '@material-ui/core/IconButton'
import IconArrowLeft from '../../assets/icons/icon-arrow-left.png'
import IconArrowRight from '../../assets/icons/icon-arrow-right.png'
import IconArrowReturn from '../../assets/icons/icon-return.svg?react'
import LinkedIn from '../../assets/icons/linkedin.svg?react'
import Twitter from '../../assets/icons/twitter.svg?react'

const useStyles = makeStyles((theme) => ({
  heroImage: {
    maxWidth: '800px',
    backgroundRepeat: 'no-Repeat',
    backgroundPosition: 'center',
    color: '#fff',
    backgroundSize: 'cover',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
    },
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(3),
  },
  goBack: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(-1),
  },
  row: {
    marginTop: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  pageTitle: {
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem',
    },
  },
  rowSubtitle: {
    marginTop: theme.spacing(5),
    color: '#555',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  rowImage: {
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    textAlign: 'justify',
  },
  item2: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 4,
    },
  },
  item4: {
    order: 4,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  item5: {
    order: 5,
    [theme.breakpoints.up('md')]: {
      order: 5,
    },
  },
  item2a: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3a: {
    order: 4,
    [theme.breakpoints.up('md')]: {
      order: 4,
    },
  },
  item4a: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  navButtons: {
    cursor: 'pointer',
    fontWeight: 700,
    color: '#555',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  alignRight: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
    },
  },
  share: {
    fontWeight: 700,
    color: '#555',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(-2),
  },
  arrowImage: {
    maxWidth: '20px',
    height: 'auto',
    float: 'left',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
  },
  floatRight: {
    float: 'right',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
  iconButton: {
    opacity: 0.7,
    '&:first-child': {
      marginLeft: theme.spacing(2),
    },
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
}))

export const News: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const news = window.location.pathname.split('/')
  const newsDataLink = news[news.length - 1]

  const newsEntry = newsPages.filter((f) => f.permalink === newsDataLink && !f.hidden)[0]
  const filteredNewsPages = newsEntry
    ? newsPages.filter((f) => !f.hidden && f.type === newsEntry.type)
    : []

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  const nextHandler = useCallback(
    (newsEntry) => {
      const index = filteredNewsPages.indexOf(newsEntry)
      if (index < filteredNewsPages.length - 1) {
        history.push(`/news/${filteredNewsPages[index + 1].permalink}`)
      }
    },
    [history],
  )

  const previousHandler = useCallback(
    (newsEntry) => {
      const index = filteredNewsPages.indexOf(newsEntry)
      if (index > 0) {
        history.push(`/news/${filteredNewsPages[index - 1].permalink}`)
      }
    },
    [history],
  )

  const showPrev: boolean = filteredNewsPages.indexOf(newsEntry) > 0
  const showNext: boolean = filteredNewsPages.indexOf(newsEntry) < filteredNewsPages.length - 1

  tagManagerHandler('PageNews')

  return !newsEntry ? (
    <></>
  ) : (
    <>
      <Container>
        <Grid item xs={12}>
          <Grid item xs={12} container justify={'flex-start'} className={classes.row}>
            <div onClick={() => buttonHandler(PATH_NEWS_AND_MEDIA)} className={classes.navButtons}>
              <IconArrowReturn className={classes.goBack} />
              {t('News-Go-Back')}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.row}>
            <Grid item xs={12} container justify={'center'}>
              <Typography
                component="h1"
                variant={'h1'}
                align="center"
                color="textPrimary"
                className={classes.pageTitle}
              >
                {t(newsEntry?.title)}
              </Typography>
            </Grid>
            <Grid item xs={12} container justify={'center'}>
              <Typography variant={'h4'} className={classes.rowSubtitle}>
                {t(newsEntry?.date)}
                {t(newsEntry?.date).length > 0 && ' • '}
                {t(newsEntry?.place)}
                {t(newsEntry?.place).length > 0 && ' • '}
                {t(newsEntry?.type)}
              </Typography>
            </Grid>
          </Box>
          <Box className={clsx(classes.row, classes.extraMargin)}>
            <Grid container>
              <Grid
                item
                xs={6}
                md={2}
                className={clsx(classes.navButtons, classes.item2, !showPrev && classes.disabled)}
                onClick={() => previousHandler(newsEntry)}
              >
                <CardMedia
                  component="img"
                  alt=""
                  className={classes.arrowImage}
                  image={IconArrowLeft}
                />
                {t('News-Previous')}
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                className={clsx(
                  classes.navButtons,
                  classes.item3,
                  classes.alignRight,
                  !showNext && classes.disabled,
                )}
                onClick={() => nextHandler(newsEntry)}
              >
                {t('News-Next')}
                <CardMedia
                  component="img"
                  alt=""
                  className={clsx(classes.arrowImage, classes.floatRight)}
                  image={IconArrowRight}
                />
              </Grid>
              <Grid className={clsx(classes.item4)} justify={'center'} item xs={12} md={8}>
                <CardMedia
                  component="img"
                  alt=""
                  className={classes.heroImage}
                  image={newsEntry?.image}
                />
                <Typography className={classes.share}>
                  {t('News-Share-Article')}
                  <IconButton
                    aria-label="LinkedIn"
                    className={classes.iconButton}
                    target="_blank"
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=https://klarpay.com/news/${
                      newsEntry?.permalink
                    }&title=${t(newsEntry?.title)} https://klarpay.com/news/${
                      newsEntry?.permalink
                    }`}
                  >
                    <LinkedIn />
                  </IconButton>
                  <IconButton
                    aria-label="Twitter"
                    className={classes.iconButton}
                    target="_blank"
                    href={`https://twitter.com/intent/tweet?text=${t(
                      newsEntry?.title,
                    )} https://klarpay.com/news/${newsEntry?.permalink}`}
                  >
                    <Twitter />
                  </IconButton>
                </Typography>
                {newsEntry?.texts?.map((p: string, i: number) => (
                  <Typography key={i} className={classes.heroContentPrefHeader}>
                    <div dangerouslySetInnerHTML={{ __html: t(p) }} />
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Box>
          <Box className={clsx(classes.row, classes.extraMargin)}>
            <Grid container>
              <Grid
                item
                xs={6}
                md={2}
                className={clsx(classes.navButtons, classes.item2a, !showPrev && classes.disabled)}
                onClick={() => previousHandler(newsEntry)}
              >
                <CardMedia
                  component="img"
                  alt=""
                  className={classes.arrowImage}
                  image={IconArrowLeft}
                />
                {t('News-Previous')}
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                className={clsx(
                  classes.navButtons,
                  classes.item3a,
                  classes.alignRight,
                  !showNext && classes.disabled,
                )}
                onClick={() => nextHandler(newsEntry)}
              >
                {t('News-Next')}
                <CardMedia
                  component="img"
                  alt=""
                  className={clsx(classes.arrowImage, classes.floatRight)}
                  image={IconArrowRight}
                />
              </Grid>
              <Grid className={clsx(classes.item4a)} justify={'center'} item xs={12} md={8}>
                <Typography className={classes.share}>
                  {t('News-Share-Article')}
                  <IconButton
                    aria-label="LinkedIn"
                    className={classes.iconButton}
                    target="_blank"
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=https://klarpay.com/news/${
                      newsEntry?.permalink
                    }&title=${t(newsEntry?.title)} https://klarpay.com/news/${
                      newsEntry?.permalink
                    }`}
                  >
                    <LinkedIn />
                  </IconButton>
                  <IconButton
                    aria-label="Twitter"
                    className={classes.iconButton}
                    target="_blank"
                    href={`https://twitter.com/intent/tweet?text=${t(
                      newsEntry?.title,
                    )} https://klarpay.com/news/${newsEntry?.permalink}`}
                  >
                    <Twitter />
                  </IconButton>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Container>
    </>
  )
}
