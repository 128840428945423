import React, { FC } from 'react'
import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  Hidden,
  Typography,
  makeStyles,
} from '@material-ui/core'
import imgHero2x from '../../assets/images/img-google-pay.jpg'
import wallet from '../../assets/images/img-google-phone.jpg'
import watch from '../../assets/images/img-watch.jpg'
import android from '../../assets/images/img-android@2x.jpg'
import EffortlesslyIcon from '../../assets/icons/icon-effortlessly.svg?react'
import ContactlessIcon from '../../assets/icons/icon-easy.svg?react'
import SecureIcon from '../../assets/icons/icon-secure.svg?react'
import IconArrow from '../../assets/icons/icon-arrow.svg?react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { tagManagerHandler } from '../../utils'

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  pageTitle: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.25rem',
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  imgWrap: {
    textAlign: 'center',
  },
  iPhoneimg: {
    display: 'inline-block',
    width: '65%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  watchImg: {
    display: 'inline-block',
    width: '82%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  readMoreButton: {
    width: 216,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  features: {
    textAlign: 'center',
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
}))

export const GooglePayPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  tagManagerHandler('PageGooglePay')

  return (
    <>
      <Container>
        <Box>
          <Grid container>
            <Grid item xs={12} justify={'center'}>
              <Typography
                component="h1"
                variant={'h1'}
                align="center"
                color="textPrimary"
                className={classes.pageTitle}
              >
                {t('GooglePay-H1')}
              </Typography>
              <Typography variant="subtitle1" align="center" component={'div'} gutterBottom>
                {t('GooglePay-P1')}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container>
            <Hidden smDown>
              <Grid item xs={1}></Grid>
            </Hidden>
            <Grid item xs={12} md={10}>
              <CardMedia component="img" alt="" height="100%" image={imgHero2x} />
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container spacing={10} className={classes.features}>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <EffortlesslyIcon />
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('GooglePay-H2')}
              </Typography>
              <Typography>{t('GooglePay-P2')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <SecureIcon />
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('GooglePay-H3')}
              </Typography>
              <Typography>{t('GooglePay-P3')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <ContactlessIcon />
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('GooglePay-H4')}
              </Typography>
              <Typography>{t('GooglePay-P4')}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={7} className={classes.rowText}>
              <Typography variant={'h2'} className={classes.rowTitle}>
                {t('GooglePay-H5')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>{t('GooglePay-P5')}</Typography>
            </Grid>

            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </Box>

        <Box mt={4}>
          <Grid container spacing={8}>
            <Grid className={classes.imgWrap} item xs={12} sm={6}>
              <CardMedia
                component="img"
                alt=""
                height="100%"
                className={classes.iPhoneimg}
                image={wallet}
              />
            </Grid>

            <Grid item xs={12} sm={6} className={classes.rowText}>
              <Typography className={classes.heroContentPrefHeader}>
                {t('GooglePay-P51')}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box mt={8}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6} className={classes.rowText}>
              <Typography className={classes.heroContentPrefHeader}>
                {t('GooglePay-P52')}
              </Typography>
            </Grid>

            <Grid className={classes.imgWrap} item xs={12} sm={6}>
              <CardMedia
                component="img"
                alt=""
                height="100%"
                className={classes.watchImg}
                image={android}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={8}>
          <Grid container spacing={8}>
            <Grid item xs={12} className={classes.rowText}>
              <Box textAlign="center">
                <Box mb={3}>
                  <Button
                    className={classes.readMoreButton}
                    size="medium"
                    variant="contained"
                    endIcon={<IconArrow />}
                    href="https://support.google.com/pay/answer/7625055?hl=en&co=GENIE.Platform%3DAndroid"
                    target="_blank"
                  >
                    {t('FindOutMore')}
                  </Button>
                </Box>
                {/*
                  <Typography>{t('ApplePay-P8')}</Typography>
                */}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.mrgbtm)}>
          <Grid container spacing={8}>
            <Grid className={classes.imgWrap} item xs={12} sm={6}>
              <CardMedia
                component="img"
                alt=""
                height="100%"
                className={classes.iPhoneimg}
                image={watch}
              />
            </Grid>

            <Grid item xs={12} sm={6} className={classes.rowText}>
              <Typography variant={'h2'} className={classes.rowTitle}>
                {t('GooglePay-H6')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>{t('GooglePay-P6')}</Typography>
              <Box my={3}>
                <Button
                  className={classes.readMoreButton}
                  size="medium"
                  variant="contained"
                  endIcon={<IconArrow />}
                  href="https://support.google.com/pay/answer/7644134"
                  target="_blank"
                >
                  {t('FindOutMore')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
