import { FC, default as React } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { Hidden, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { tagManagerHandler } from '../../utils'
import dataSubProcessors from './processors'

const useStyles = makeStyles((theme) => ({
  greyBox: {
    backgroundColor: '#fafafa',
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  row: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    '& .MuiTableCell-body': {
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
    },
  },
  pageTitle: {
    marginTop: theme.spacing(8),
    fontSize: '3rem',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
      fontSize: '2.25rem',
    },
  },
  pageSubtitle: {
    fontSize: '2.75rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  header: {
    opacity: 1,
  },
  arrow: {
    width: '24px',
    marginLeft: theme.spacing(1),
  },
}))

export const DataProcessorsPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  tagManagerHandler('PageDataProcessors')

  return (
    <>
      <Container>
        <Box>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={classes.pageTitle}
            >
              {t('DataProcessors-H1')}
            </Typography>
          </Grid>
        </Box>

        <Hidden smDown>
          <Box className={classes.row}>
            <Grid item xs={12} container style={{ margin: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {Array.from(Array(5), (e, i) => (
                      <TableCell className={classes.header} key={i}>
                        <b>{t(`DataProcessors-Table-${i + 1}`)}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSubProcessors.map((subProcessor: any, i: number) => (
                    <>
                      <TableRow
                        key={`subProcessor-${i}`}
                        style={{ backgroundColor: i % 2 === 0 ? '#eee' : '' }}
                      >
                        <TableCell>{subProcessor.name}</TableCell>
                        <TableCell>
                          <div
                            dangerouslySetInnerHTML={{ __html: subProcessor.subprocessorLocation }}
                          />
                        </TableCell>
                        <TableCell>
                          <div dangerouslySetInnerHTML={{ __html: subProcessor.klarpayLocation }} />
                        </TableCell>
                        <TableCell>{subProcessor.klarpayServices}</TableCell>
                        <TableCell>{subProcessor.description}</TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Box>
        </Hidden>

        <Hidden mdUp>
          <Box className={classes.row}>
            <Grid item xs={12} container style={{ margin: 'auto' }}>
              {dataSubProcessors.map((subProcessor: any, i: number) => [
                <Table
                  className={classes.row}
                  style={{ backgroundColor: i % 2 === 0 ? '#eee' : '' }}
                  key={`subProcessor-${i}`}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>{t(`DataProcessors-Table-1`)}</b>
                      </TableCell>
                      <TableCell>{subProcessor.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>{t(`DataProcessors-Table-2`)}</b>
                      </TableCell>
                      <TableCell>
                        <div
                          dangerouslySetInnerHTML={{ __html: subProcessor.subprocessorLocation }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>{t(`DataProcessors-Table-3`)}</b>
                      </TableCell>
                      <TableCell>
                        <div dangerouslySetInnerHTML={{ __html: subProcessor.klarpayLocation }} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>{t(`DataProcessors-Table-4`)}</b>
                      </TableCell>
                      <TableCell>{subProcessor.klarpayServices}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>{t(`DataProcessors-Table-5`)}</b>
                      </TableCell>
                      <TableCell>{subProcessor.description}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>,
              ])}
            </Grid>
          </Box>
        </Hidden>
      </Container>
    </>
  )
}
