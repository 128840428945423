import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

import { FormInputFieldEntity } from '../../../types'

export const FormControlledTextField: React.FC<FormInputFieldEntity> = ({
  type,
  name,
  label,
  placeholder,
  defaultValue = '',
  ...rest
}) => {
  const { t } = useTranslation()
  const { control, errors } = useFormContext()
  const error = errors ? errors[name] : null

  return (
    <Controller
      as={
        <TextField
          label={label}
          type={!!type ? type : 'text'}
          placeholder={placeholder}
          error={!!error}
          helperText={error && t(error.message)}
          variant="standard"
          size="small"
          margin="normal"
          InputProps={{
            margin: 'none',
          }}
          fullWidth
          {...rest}
        />
      }
      id={name}
      control={control}
      name={name}
      defaultValue={defaultValue}
    />
  )
}
