import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  login: {
    display: 'block',
    lineHeight: '24px',
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontSize: '0.875rem',
    textDecoration: 'none',
    color: 'black',
    whiteSpace: 'normal',
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      paddingLeft: 32,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 32,
      paddingLeft: 32,
    },
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
}))

export const Login: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <a
        className={classes.login}
        href={`https://dashboard.${window.location.host.replace('www.', '')}/`}
      >
        {t('Login')}
      </a>
    </>
  )
}
