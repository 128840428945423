export const PATH_ROOT = '/'
export const PATH_HOME = '/home'
export const PATH_USE_CASES = '/use-cases'
export const PATH_OVERVIEW = '/overview'
export const PATH_MARKET_PLACES = '/marketplaces'
export const PATH_MEDIA_INFLUENCERS = '/media-influencers'
export const PATH_AFFILIATE_NETWORKS = '/affiliate-networks'
export const PATH_ECOMMERCE = '/online-merchants'
export const PATH_FINTECHS = '/fintechs'
export const PATH_FEATURES = '/cards'
export const PATH_APPLE_PAY = '/apple-pay'
export const PATH_GOOGLE_PAY = '/google-pay'
export const PATH_VIRTUAL_IBAN_ACCOUNTS = '/virtual-iban-accounts'
export const PATH_CROSS_BORDER_PAYMENTS = '/cross-border-payments'
export const PATH_API = '/api'
export const PATH_ENTREPRENEUR_ACCOUNTS = '/entrepreneur-accounts'
export const PATH_ABOUT = '/about'
export const PATH_KLARPAY = '/klarpay'
export const PATH_BOARD_DIRECTORS = '/board-directors'
export const PATH_EXECUTIVE_MANAGEMENT = '/management'
export const PATH_ADVISORS = '/advisors'
export const PATH_NEWS_AND_MEDIA = '/news'
export const PATH_NEWS_PAGES = '/news/pages'
export const PATH_NEWS_PAGES_CATEGORY = '/news/pages/:category'
export const PATH_NEWS_PAGES_CATEGORY_PAGES = '/news/pages/:category/:page'
export const PATH_NEWS_ENTRIES = '/news/:id'
export const PATH_FAQ = '/faq'
export const PATH_TALKTOUS = '/talk-to-us'
export const PATH_PARTNERSHIPS = '/partnerships'
export const PATH_IMPRESSUM = '/impressum'
export const PATH_IMPRINT = '/imprint'
export const PATH_CORRESPONDENT_BANKS = '/correspondent-banks'
export const PATH_PRIVACY_POLICY = '/privacy'
export const PATH_DATA_PROCESSORS = '/data-processors'
export const PATH_MESSAGE_SENT = '/message-sent'
export const PATH_404 = '/not-found'
