import React, { FC } from 'react'
import { Container, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import logo from '../../assets/images/logo.svg'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import clsx from 'clsx'
import LinkedIn from '../../assets/icons/linkedin.svg?react'
import Twitter from '../../assets/icons/twitter.svg?react'
import Youtube from '../../assets/icons/icon-youtube.svg?react'
import Medium from '../../assets/icons/icon-medium.svg?react'
import {
  PATH_ABOUT,
  PATH_AFFILIATE_NETWORKS,
  PATH_BOARD_DIRECTORS,
  PATH_ECOMMERCE,
  PATH_EXECUTIVE_MANAGEMENT,
  PATH_FINTECHS,
  PATH_MARKET_PLACES,
  PATH_MEDIA_INFLUENCERS,
  PATH_ROOT,
  PATH_USE_CASES,
  PATH_APPLE_PAY,
  PATH_NEWS_AND_MEDIA,
  PATH_ADVISORS,
  PATH_FEATURES,
  PATH_GOOGLE_PAY,
  PATH_CROSS_BORDER_PAYMENTS,
  PATH_VIRTUAL_IBAN_ACCOUNTS,
  PATH_API,
  PATH_PARTNERSHIPS,
  PATH_FAQ,
  PATH_TALKTOUS,
  PATH_ENTREPRENEUR_ACCOUNTS,
} from '../../constants'
import { Theme } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#FAFAFA',
    [theme.breakpoints.up('md')]: {
      '& .MuiListItem-gutters': {
        marginLeft: '-16px',
      },
    },
    '& .MuiContainer-root': {
      // maxWidth: 1072,
    },
  },
  subFooterGrid: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      // alignItems: 'center',
      // whiteSpace: 'nowrap',
      // textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  footerNavColumnList: {
    display: 'block',
    marginTop: '22px',
    [theme.breakpoints.down('sm')]: {
      '& .MuiListItem-root': {
        textAlign: 'center',
      },
    },
  },
  body: {
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontSize: '1rem',
    textDecoration: 'unset',
    color: 'black',
  },
  body2: {
    display: 'block',
    marginBottom: '22px',
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontSize: '0.875rem',
    opacity: '64%',
    textDecoration: 'none',
    color: 'black',
    whiteSpace: 'normal',
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  mrgTop: {
    marginTop: theme.spacing(5),
  },
  subFooterNav: {
    justifyContent: 'space-around',
    '& .MuiListItemText-primary': {
      fontSize: 'inherit',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  subFooterNavItem: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      width: '20%',
      maxWidth: 200,
      minWidth: 100,
    },
    '& .MuiTypography-subtitle2': {
      fontSize: 'inherit',
    },
  },
  socials: {
    color: '#555',
    '& .MuiIconButton-root': {
      marginTop: theme.spacing(-1.5),
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: theme.spacing(-1),
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    marginBottom: theme.spacing(5),
  },
  logoImg: {
    display: 'inline-block',
  },
  phrase: {
    fontSize: '14px',
  },
  leftMargin: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(-2),
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}))

export const SubFooter: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box pt={6} mt={6} className={classes.root}>
      <Container>
        <Grid container spacing={3} className={classes.subFooterGrid}>
          <Grid item>
            <Grid container direction={'column'}>
              <Grid item>
                <Box className={classes.socials}>
                  <Link className={classes.logoImg} to={PATH_ROOT}>
                    <img src={logo} alt={'Klarpay AG'} />
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box className={classes.socials}>
                  <Typography className={classes.phrase}>{t('Footer-Phrase')}</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Grid item>
                  <Grid container spacing={1} className={clsx(classes.socials, classes.leftMargin)}>
                    <IconButton
                      aria-label="LinkedIn"
                      target="_blank"
                      href="https://www.linkedin.com/company/klarpay/"
                    >
                      <LinkedIn />
                    </IconButton>
                    <IconButton
                      aria-label="Twitter"
                      target="_blank"
                      href="https://twitter.com/klarpay"
                    >
                      <Twitter />
                    </IconButton>
                    <IconButton
                      aria-label="Youtube"
                      target="_blank"
                      href="https://www.youtube.com/@klarpayag"
                    >
                      <Youtube />
                    </IconButton>
                    <IconButton
                      aria-label="Medium"
                      target="_blank"
                      href="https://medium.com/@Klarpay_AG"
                    >
                      <Medium />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container className={classes.subFooterNav}>
            <Grid item className={classes.subFooterNavItem}>
              <Grid container direction={'column'}>
                <Link className={classes.body} to={PATH_USE_CASES}>
                  <Typography variant={'subtitle2'}>{t('MainMenu-P15')}</Typography>
                </Link>
                <List
                  component="nav"
                  className={classes.footerNavColumnList}
                  disablePadding={false}
                >
                  <Link className={classes.body2} to={PATH_USE_CASES}>
                    <ListItemText primary={t('MainMenu-P3')} />
                  </Link>

                  <Link className={classes.body2} to={PATH_AFFILIATE_NETWORKS}>
                    <ListItemText primary={t('MainMenu-P4')} />
                  </Link>

                  <Link className={classes.body2} to={PATH_MARKET_PLACES}>
                    <ListItemText primary={t('MainMenu-P5')} />
                  </Link>

                  <Link className={classes.body2} to={PATH_MEDIA_INFLUENCERS}>
                    <ListItemText primary={t('MainMenu-P6')} />
                  </Link>

                  <Link className={classes.body2} to={PATH_ECOMMERCE}>
                    <ListItemText primary={t('MainMenu-P7')} />
                  </Link>

                  <Link className={classes.body2} to={PATH_FINTECHS}>
                    <ListItemText primary={t('MainMenu-P8')} />
                  </Link>
                </List>
              </Grid>
            </Grid>

            <Grid item className={classes.subFooterNavItem}>
              <Grid container direction={'column'}>
                <Link className={classes.body} to={PATH_FEATURES}>
                  <Typography variant={'subtitle2'}>{t('MainMenu-P20')}</Typography>
                </Link>
                <List
                  component="nav"
                  className={classes.footerNavColumnList}
                  disablePadding={false}
                >
                  <Link className={classes.body2} to={PATH_VIRTUAL_IBAN_ACCOUNTS}>
                    <ListItemText primary={t('MainMenu-P26')} />
                  </Link>
                  <Link className={classes.body2} to={PATH_ENTREPRENEUR_ACCOUNTS}>
                    <ListItemText primary={t('MainMenu-P30')} />
                  </Link>
                  <Link className={classes.body2} to={PATH_CROSS_BORDER_PAYMENTS}>
                    <ListItemText primary={t('MainMenu-P25')} />
                  </Link>
                  <Link className={classes.body2} to={PATH_API}>
                    <ListItemText primary={t('MainMenu-P27')} />
                  </Link>
                  <Link className={classes.body2} to={PATH_FEATURES}>
                    <ListItemText primary={t('MainMenu-P23')} />
                  </Link>
                  <Link className={classes.body2} to={PATH_APPLE_PAY}>
                    <ListItemText primary={t('MainMenu-P19')} />
                  </Link>
                  <Link className={classes.body2} to={PATH_GOOGLE_PAY}>
                    <ListItemText primary={t('MainMenu-P24')} />
                  </Link>
                </List>
              </Grid>
            </Grid>

            <Grid item className={classes.subFooterNavItem}>
              <Link className={classes.body} to={PATH_ABOUT}>
                <Typography variant={'subtitle2'}>{t('MainMenu-P16')}</Typography>
              </Link>
              <List component="nav" className={classes.footerNavColumnList} disablePadding={false}>
                <Link className={classes.body2} to={PATH_ABOUT}>
                  <ListItemText primary={t('MainMenu-P11')} />
                </Link>

                <Link className={classes.body2} to={PATH_BOARD_DIRECTORS}>
                  <ListItemText primary={t('MainMenu-P12')} />
                </Link>

                <Link className={classes.body2} to={PATH_EXECUTIVE_MANAGEMENT}>
                  <ListItemText primary={t('MainMenu-P13')} />
                </Link>

                <Link className={classes.body2} to={PATH_ADVISORS}>
                  <ListItemText primary={t('MainMenu-P22')} />
                </Link>

                <Link className={classes.body2} to={PATH_NEWS_AND_MEDIA}>
                  <ListItemText primary={t('MainMenu-P21')} />
                </Link>

                <Link className={classes.body2} to={PATH_FAQ}>
                  <ListItemText primary={t('MainMenu-P29')} />
                </Link>
              </List>
            </Grid>

            <Grid item className={classes.subFooterNavItem}>
              <Box mb={3}>
                <Link className={classes.body} to={PATH_PARTNERSHIPS}>
                  <Typography variant={'subtitle2'}>{t('MainMenu-P28')}</Typography>
                </Link>
              </Box>
            </Grid>

            <Grid item className={classes.subFooterNavItem}>
              <Box mb={3}>
                <Link className={classes.body} to={PATH_TALKTOUS}>
                  <Typography variant={'subtitle2'}>{t('TalkToUs')}</Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
