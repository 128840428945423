import React, { FC } from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  minW: {
    minWidth: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    padding: theme.spacing(5),
    backgroundColor: '#FAFAFA',
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  heroContentPrefHeader: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '5%',
      paddingBottom: '10%',
      textAlign: 'center',
    },
  },
}))

export const TipPage: FC<{ title: string; emailAddress: string }> = ({ title, emailAddress }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.row} mb={12}>
      <Grid
        container
        className={classes.minW}
        item
        xs={12}
        direction={'column'}
        alignItems={'center'}
        justify={'center'}
      >
        <Typography variant={'subtitle2'} className={classes.heroContentPrefHeader}>
          {t('Tips-Reach-Out')} {title}
        </Typography>
        <Typography variant={'subtitle2'}>{emailAddress}</Typography>
      </Grid>
    </Box>
  )
}
