export default [
  {
    name: 'Swift',
    subprocessorLocation: 'Belgium',
    klarpayLocation: 'Klarpay AG <br/>Switzerland',
    klarpayServices: 'Corporate Swiss Accounts',
    description: 'Banking Infrastructure',
  },
  {
    name: 'JP Morgan',
    subprocessorLocation: 'New York, USA',
    klarpayLocation: 'Klarpay AG <br/>Switzerland',
    klarpayServices: 'Corporate Swiss Accounts',
    description: 'Banking Infrastructure',
  },
  {
    name: 'VISA',
    subprocessorLocation: 'USA',
    klarpayLocation: 'Klarpay AG <br/>Switzerland',
    klarpayServices: 'Corporate Swiss Accounts',
    description: 'Banking Infrastructure',
  },
  {
    name: 'Google LLC',
    subprocessorLocation: 'USA',
    klarpayLocation: 'Klarpay AG <br/>Switzerland',
    klarpayServices: 'Corporate Swiss Accounts',
    description: 'Email services, file storage, page analytics',
  },
  {
    name: 'Sendgrid',
    subprocessorLocation: 'Twilio <br/>USA',
    klarpayLocation: 'Klarpay AG <br/>Switzerland',
    klarpayServices: 'Corporate Swiss Accounts',
    description: 'Technology Service Providers',
  },
  {
    name: 'Atlassian',
    subprocessorLocation: 'USA',
    klarpayLocation: 'Klarpay AG <br/>Switzerland',
    klarpayServices: 'Corporate Swiss Accounts',
    description: 'Technology Service Providers',
  },
  {
    name: 'Salv',
    subprocessorLocation: 'Estonia',
    klarpayLocation: 'Klarpay AG <br/>Switzerland',
    klarpayServices: 'Corporate Swiss Accounts',
    description: 'Compliance Screening',
  },
  {
    name: 'Fully-Verified',
    subprocessorLocation: 'Estonia',
    klarpayLocation: 'Klarpay AG <br/>Switzerland',
    klarpayServices: 'Corporate Swiss Accounts',
    description: 'Document Verification',
  },
  {
    name: 'Kompany',
    subprocessorLocation: 'Austria',
    klarpayLocation: 'Klarpay AG <br/>Switzerland',
    klarpayServices: 'Corporate Swiss Accounts',
    description: 'Compliance Screening',
  },
]
