import { FC, default as React, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import IconArrow from '../../assets/icons/icon-arrow.svg?react'
import SeamlessIcon from '../../assets/icons/icon-seamless-integration.svg?react'
import CostIcon from '../../assets/icons/icon-cost-effective.svg?react'
import SupportIcon from '../../assets/icons/icon-support.svg?react'
import Capabilties1 from '../../assets/icons/icon-capabilities-1.svg?react'
import Capabilties2 from '../../assets/icons/icon-capabilities-2.svg?react'
import Capabilties3 from '../../assets/icons/icon-capabilities-3.svg?react'
import Capabilties4 from '../../assets/icons/icon-capabilities-4.svg?react'
import Businesses1 from '../../assets/icons/icon-businesses-1.svg?react'
import Businesses2 from '../../assets/icons/icon-businesses-2.svg?react'
import Businesses3 from '../../assets/icons/icon-businesses-3.svg?react'
import imgApiConsole from '../../assets/images/img-api-seamless-integration-2@2x.png'
import imgApiGraphic from '../../assets/images/img-api@2x.png'
import { tagManagerHandler } from '../../utils'
import { PATH_TALKTOUS } from '../../constants'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContentPref: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  iPhoneimg: {
    display: 'inline-block',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  icon: {
    justifyContent: 'center',
  },
  item2: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  greyBox: {
    backgroundColor: '#fafafa',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(1),
    paddingBotoom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(-12),
      marginRight: theme.spacing(-12),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
  },
  readMoreButton: {
    width: 216,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  footerNavColumns: {
    justifyContent: 'space-between',
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  footerNavColumnList: {
    display: 'block',

    MuiListItemButton: {
      padding: 0,
    },
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  subtitleBoxIcon: {
    body1: {
      fontSize: '1.125rem',
      fontWeight: 'normal',
    },
  },
  minW: {
    minWidth: '250px',
  },
  pad2x: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  pad2xBiased: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(0),
      marginBottom: theme.spacing(-2),
    },
  },
  pad8x: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginTop: theme.spacing(-8),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  row: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  pageTitle: {
    marginTop: theme.spacing(8),
    fontSize: '3rem',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
      fontSize: '2.25rem',
    },
  },
  pageSubtitle: {
    fontSize: '2.75rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  pageEndTitle: {
    fontSize: '2rem',
    lineHeight: '50px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem',
      lineHeight: '30px',
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  features: {
    textAlign: 'center',
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  h3: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
  alignCenterXs: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
  },
  imgBottom: {
    padding: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
    },
  },
}))

export const ApiPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const apiOpenHandler = useCallback(() => {
    window.open('https://api.klarpay.com/docs')
  }, [])

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  tagManagerHandler('PageApi')

  return (
    <>
      <Container>
        <Box>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={classes.pageTitle}
            >
              {t('Api-H1')}
            </Typography>
          </Grid>
          <Grid item md={8} xs={11} container style={{ margin: 'auto', textAlign: 'center' }}>
            <Box mt={3}>
              <Typography>{t('Api-P1')}</Typography>
            </Box>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.greyBox)}>
          <Grid container spacing={2}>
            <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
              <Box className={classes.pad8x}>
                <Typography
                  component="h1"
                  variant={'h1'}
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  className={clsx(classes.pageTitle, classes.pageSubtitle, classes.alignCenterXs)}
                >
                  {t('Api-P11')}
                </Typography>
                <Typography>
                  <div dangerouslySetInnerHTML={{ __html: t('Api-P12') }} />
                </Typography>
                <Box mt={6} className={clsx(classes.buttonContainer)}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="secondary"
                    endIcon={<IconArrow />}
                    onClick={apiOpenHandler}
                    className={classes.getStartedButton}
                  >
                    {t('Api-P13')}
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
              <Box className={clsx(classes.pad2x)}>
                <Grid item xs={12} md={12}>
                  <CardMedia component="img" alt="" height="100%" image={imgApiConsole} />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row} mb={8}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={clsx(classes.pageTitle, classes.pageSubtitle)}
            >
              {t('Api-H2')}
            </Typography>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container spacing={5} className={classes.features} alignContent={'center'}>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <SeamlessIcon />
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('Api-H3')}
              </Typography>
              <Typography>{t('Api-P3')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <CostIcon />
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('Api-H4')}
              </Typography>
              <Typography>{t('Api-P4')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <SupportIcon />
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('Api-H5')}
              </Typography>
              <Typography>{t('Api-P5')}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.greyBox)}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={clsx(classes.pageTitle, classes.pageSubtitle)}
            >
              {t('Api-H6')}
            </Typography>
          </Grid>
          <Grid container spacing={5} className={classes.features} alignContent={'center'}>
            <Grid item xs={12} md={3}>
              <Box p={4}>
                <Capabilties1 />
              </Box>
              <Typography>{t('Api-P61')}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box p={4}>
                <Capabilties2 />
              </Box>
              <Typography>{t('Api-P62')}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box p={4}>
                <Capabilties3 />
              </Box>
              <Typography>{t('Api-P63')}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box p={4}>
                <Capabilties4 />
              </Box>
              <Typography>{t('Api-P64')}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row} mb={8}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={clsx(classes.pageTitle, classes.pageSubtitle)}
            >
              {t('Api-H7')}
            </Typography>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container spacing={5} className={classes.features} alignContent={'center'}>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <Businesses1 />
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('Api-H8')}
              </Typography>
              <Typography>{t('Api-P8')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <Businesses2 />
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('Api-H9')}
              </Typography>
              <Typography>{t('Api-P9')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <Businesses3 />
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('Api-H10')}
              </Typography>
              <Typography>{t('Api-P10')}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.greyBox)}>
          <Grid container spacing={2}>
            <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
              <Box className={classes.pad8x}>
                <Typography
                  component="h2"
                  variant={'h2'}
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  className={clsx(classes.pageTitle, classes.pageEndTitle, classes.alignCenterXs)}
                >
                  {t('Api-H11')}
                </Typography>
              </Box>
            </Grid>

            <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
              <Box className={classes.imgBottom}>
                <Grid item xs={12} md={12}>
                  <CardMedia component="img" alt="" height="100%" image={imgApiGraphic} />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Container>
          <Box className={clsx(classes.row, classes.extraMargin, classes.mrgbtm)}>
            <Grid container direction={'column'} alignItems="center">
              <Grid item xs={12} md={10}>
                <Typography
                  variant={'h2'}
                  align="center"
                  color="textPrimary"
                  gutterBottom
                  className={classes.rowTitle}
                >
                  {t('COMMON-CTA-Title')}
                </Typography>
              </Grid>

              <Box mt={1} className={classes.buttonContainer}>
                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  className={classes.getStartedButton}
                  endIcon={<IconArrow />}
                  onClick={() => buttonHandler(PATH_TALKTOUS)}
                >
                  {t('COMMON-CTA-Consultation')}
                </Button>
              </Box>
            </Grid>
          </Box>
        </Container>
      </Container>
    </>
  )
}
