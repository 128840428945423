import React, { FC, useCallback } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import yellowbus from '../../assets/images/img-global-ecommerce@2x.jpg'
import marketplace from '../../assets/images/img-online-marketplaces@2x.jpg'
import network from '../../assets/images/img-affiliate-networks@2x.jpg'
import influencer from '../../assets/images/img-influencers@2x.jpg'
import fintech from '../../assets/images/img-fintech@2x.jpg'
import Container from '@material-ui/core/Container'
import { useHistory } from 'react-router'
import {
  PATH_AFFILIATE_NETWORKS,
  PATH_ECOMMERCE,
  PATH_FINTECHS,
  PATH_MARKET_PLACES,
  PATH_MEDIA_INFLUENCERS,
} from '../../constants'
import clsx from 'clsx'
import IconArrow from '../../assets/icons/icon-arrow.svg?react'
import { useTranslation } from 'react-i18next'
import { tagManagerHandler } from '../../utils'

const useStyles = makeStyles((theme) => ({
  heroImage: {
    maxWidth: '100%',
    backgroundRepeat: 'no-Repeat',
    backgroundPosition: 'center',
    color: '#fff',
    position: 'relative',
    backgroundSize: 'cover',
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
  },
  readMoreButton: {
    width: 216,
    height: 54,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  row: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  pageTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
      fontSize: '2.25rem',
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  item2: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
}))

export const UseCasesPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  tagManagerHandler('PageUseCases')

  return (
    <>
      <Container>
        <Box className={classes.row}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              className={classes.pageTitle}
            >
              {t('Usecases')}
            </Typography>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container spacing={2}>
            <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
              <Typography variant={'h2'} className={classes.rowTitle}>
                {t('Usecases-H1')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>{t('Usecases-P11')}</Typography>
              <Typography className={classes.heroContentPrefHeader}>{t('Usecases-P12')}</Typography>
              <Button
                className={classes.readMoreButton}
                size="medium"
                variant="contained"
                onClick={() => buttonHandler(PATH_ECOMMERCE)}
                endIcon={<IconArrow />}
              >
                {t('Usecases-P13')}
              </Button>
            </Grid>

            <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
              <Grid item xs={12} md={10}>
                <CardMedia component="img" alt="" height="100%" image={yellowbus} />
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.extraMargin)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} container>
              <Grid item xs={12} md={10}>
                <CardMedia component="img" alt="" height="100%" image={marketplace} />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} className={classes.rowText}>
              <Typography variant={'h2'} className={classes.rowTitle}>
                {t('Usecases-H2')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>{t('Usecases-P21')}</Typography>
              <Button
                className={classes.readMoreButton}
                size="medium"
                variant="contained"
                endIcon={<IconArrow />}
                onClick={() => buttonHandler(PATH_MARKET_PLACES)}
              >
                {t('Usecases-P22')}
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.extraMargin)}>
          <Grid container spacing={2}>
            <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
              <Typography variant={'h2'} className={classes.rowTitle}>
                {t('Usecases-H3')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>{t('Usecases-P31')}</Typography>
              <Button
                className={classes.readMoreButton}
                size="medium"
                variant="contained"
                onClick={() => buttonHandler(PATH_AFFILIATE_NETWORKS)}
                endIcon={<IconArrow />}
              >
                {t('Usecases-P32')}
              </Button>
            </Grid>

            <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
              <Grid item xs={12} md={10}>
                <CardMedia component="img" alt="" height="100%" image={network} />
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.extraMargin)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} container>
              <Grid item xs={12} md={10}>
                <CardMedia component="img" alt="" height="100%" image={influencer} />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} className={classes.rowText}>
              <Typography variant={'h2'} className={classes.rowTitle}>
                {t('Usecases-H4')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>{t('Usecases-P41')}</Typography>
              <Button
                className={classes.readMoreButton}
                size="medium"
                variant="contained"
                onClick={() => buttonHandler(PATH_MEDIA_INFLUENCERS)}
                endIcon={<IconArrow />}
              >
                {t('Usecases-P42')}
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.extraMargin, classes.mrgbtm)}>
          <Grid container spacing={2}>
            <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
              <Typography variant={'h2'} className={classes.rowTitle}>
                {t('Usecases-H5')}
              </Typography>
              <Typography className={classes.heroContentPrefHeader}>{t('Usecases-P51')}</Typography>
              <Button
                className={classes.readMoreButton}
                size="medium"
                variant="contained"
                onClick={() => buttonHandler(PATH_FINTECHS)}
                endIcon={<IconArrow />}
              >
                {t('Usecases-P52')}
              </Button>
            </Grid>

            <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
              <Grid item xs={12} md={10}>
                <CardMedia component="img" alt="" height="100%" image={fintech} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
