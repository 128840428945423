import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import { countries, Country } from '@klarpay/enums'
import { ControlledTextFieldProps } from '../../../types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    margin: theme.spacing(3, 0, 2.5),
    maxWidth: '100%',
  },
  input: {},
  readOnly: {
    '& .MuiFormLabel-root ': {
      zIndex: 1,
    },
    '& .MuiInput-root': {
      backgroundColor: '#F5F5F5',
      pointerEvents: 'none',
    },
    '& .MuiIconButton-root': {
      color: '#999999',
    },
    '& .MuiInputBase-root': {
      color: '#999999',
    },
  },
}))

type CountryAutocompleteSelectProps = {
  name: string
  defaultValue?: string | undefined
  clearFields?: () => void
  readOnly?: boolean | undefined
  setDisableParentScroll?: React.Dispatch<React.SetStateAction<boolean>>
}
const defaultRules = {} //{ required: true }

const filterOptionsStart = createFilterOptions({
  matchFrom: 'start',
})

const CountriesCheck = Object.keys(countries)
const CountriesList = Object.values<Country>(countries).sort(
  (a, b) => -b.label.localeCompare(a.label),
)

export const CountryAutocompleteSelect: React.FC<
  CountryAutocompleteSelectProps & Partial<ControlledTextFieldProps>
> = ({
  name = '',
  defaultValue = '',
  readOnly = false,
  clearFields,
  rules,
  setDisableParentScroll,
  ...rest
}) => {
  const classes = useStyles()
  const { errors, register, setValue, watch, getValues } = useFormContext()
  const error = errors ? errors[name || 'FormAutocompleteSelect'] : null

  useEffect(() => {
    if (!getValues(name)) {
      register(name, { ...defaultRules, ...(rules ?? {}) })
    }
  }, [register, getValues, name, rules])

  return (
    <Autocomplete
      className={`${classes.formField} ${readOnly ? classes.readOnly : ''}`}
      options={CountriesList}
      onOpen={() => setDisableParentScroll && setDisableParentScroll(true)}
      onClose={() => setDisableParentScroll && setDisableParentScroll(false)}
      getOptionLabel={(option) => (option as Country).label}
      value={CountriesList?.find((v: Country) => v.key === watch(name))}
      onChange={(event, option) => {
        if (clearFields) {
          clearFields()
        }
        setValue(name, option ? (option as Country).key : defaultValue, {
          shouldValidate: true,
          shouldDirty: true,
        })
      }}
      defaultValue={CountriesList?.find((v: Country) => v.key === watch(name))}
      renderInput={(params) => (
        <TextField
          className={classes.input}
          name={name}
          {...params}
          error={!!error}
          helperText={error ? error.message : null}
          {...rest}
          disabled={readOnly}
        />
      )}
      filterOptions={filterOptionsStart}
      openText={''}
      closeText={''}
      popupIcon={<ExpandMoreIcon />}
      disabled={readOnly}
    />
  )
}
