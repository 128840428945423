import React, { FC, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
  accountButtons: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .MuiButton-root': {
      minWidth: 128,
      minHeight: 36,
      lineHeight: '24px',
      boxShadow: 'none',
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(1.5),
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        fontWeight: 600,
      },
      '& .MuiButton-label': {
        fontSize: '0.875rem',
      },
    },
  },
}))

export const Account: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const buttonHandler = useCallback(() => {
    window.location.href = `https://dashboard.${window.location.host.replace('www.', '')}/signup`
  }, [history])

  return (
    <React.Fragment>
      <Box className={classes.accountButtons}>
        <Button
          size="medium"
          variant="contained"
          fullWidth
          color="secondary"
          disableElevation
          onClick={() => buttonHandler()}
        >
          {t('SignUp')}
        </Button>
      </Box>
    </React.Fragment>
  )
}
