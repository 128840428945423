import { Trans, useTranslation } from 'react-i18next'
import { CountryCode, NationalNumber, parsePhoneNumber } from 'libphonenumber-js'
import { toast } from 'react-toastify'
import { FieldError, useFormContext } from 'react-hook-form'
import { filter, startsWith, toLower } from 'lodash'
import React, {
  ChangeEventHandler,
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react'
import {
  FormControl,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Box,
  Button,
  FormHelperText,
  makeStyles,
  Paper,
  Grow,
  Popper,
  ClickAwayListener,
  List,
} from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp, Check, Search } from '@material-ui/icons'

import { countries } from '@klarpay/enums'
import { Country } from '@klarpay/enums'

import { ControlledTextFieldProps } from '../../../types'
import { FlagIcon } from '../FlagIcon'
import { countriesArray, DEFAULT_COUNTRY_CALLING_CODE, prefixCode } from '../../../constants'

const useStyles = (error: FieldError) =>
  makeStyles((theme) => ({
    root: {},
    popper: {
      zIndex: 2,
      width: '100%',
    },
    paper: {
      marginRight: 0,
      maxHeight: 400,
      maxWidth: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      background: '#FFFFFF',
      borderRadius: 0,
      boxShadow: '0 3.5px 14px rgba(0, 0, 0, 0.2)',
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        outline: '1px solid #efefef',
        borderRadius: '0.05em',
      },
    },
    countryPhone: {
      // margin: theme.spacing(3, 0, 2.5),
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        // alignItems: 'flex-end',
        position: 'relative',
        flexDirection: 'column',
      },
    },
    basePart: {
      display: 'flex',
      alignItems: 'flex-end',
      position: 'relative',
      flexDirection: 'row',
    },
    searchField: {
      width: '100%',
      '& .MuiTextField-root': {
        margin: theme.spacing(0.5, 1, 1, 1),
        padding: theme.spacing(0),
        minHeight: 'auto',
      },
      '& .MuiInput-root': {
        paddingLeft: theme.spacing(1.5),
      },
      '& .MuiInputBase-input': {
        height: 48,
        boxSizing: 'border-box',
      },
      '& .MuiInput-underline:before': {
        height: '100%',
        border: '1px solid #D9D9D9',
      },
      '& .MuiSvgIcon-root': {
        fill: '#999',
      },
    },
    inputWrap: {
      display: 'flex',
      alignItems: 'flex-end',
      position: 'relative',
      width: '100%',
    },
    phonePrefix: {
      flexGrow: 1,
      flexBasis: 32,
      '& .MuiInput-root': {
        minHeight: '48px',
        alignItems: 'flex-end',
      },
      '& .MuiInputBase-input': {
        width: 'auto',
        paddingLeft: 4,
        paddingRight: 4,
        color: 'black',
      },
      '& .MuiInput-formControl.Mui-disabled': {
        backgroundColor: 'transparent',
      },
      '& .MuiInput-underline.Mui-disabled': {
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
    },
    phoneInput: {
      flexGrow: 3,
      flexBasis: 'calc(100% - 68px - 32px)',
      minHeight: 'auto',
      position: 'static',
      '& .MuiInputLabel-shrink + .MuiInput-root .MuiInput-input': {
        paddingTop: 0,
      },
      '& .MuiInputLabel-formControl': {
        transform: 'translate(0, -3px) scale(0.857)',
        transformOrigin: 'top left',
        padding: 0,
      },
      '& .MuiInputBase-input': {
        paddingLeft: 4,
      },
    },
    countryFlag: {
      flex: '0 0',
      cursor: 'pointer',
      minWidth: 68,
      minHeight: 64,
      background: 'none',
      padding: theme.spacing(1.5, 0.5, 1.5, 1.5),
      boxShadow: 'inset -1px -2px 0px -1px #ccc',
      // borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      position: 'relative',
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'inset -1px -3px 0px -1px #000',
      },
      '& .flag-icon-lg': {
        fontSize: '1em',
      },
    },
    item: {
      padding: theme.spacing(1, 1, 1, 1),
      '& .MuiListItemIcon-root': {
        minWidth: '1.5em',
        marginRight: theme.spacing(2),
      },
      '& .flag-icon-lg': {
        fontSize: '1.5em',
      },
    },
    errorFlag: {
      boxShadow: 'inset -1px -3px 0px -1px #EF2828',
    },
    redError: {
      color: '#EF2828',
    },
  }))()

function checkPhone(value: string, countryKey: string) {
  try {
    return parsePhoneNumber(value, countryKey as CountryCode | undefined).isValid()
  } catch (e) {
    return false
  }
}

type MenuListCompositionProps = {
  countryCode: string
  error: boolean
  countryKey: string | undefined
  setCountryKey: React.Dispatch<React.SetStateAction<string | undefined>>
  value: string | NationalNumber
  name: string
  componentDisabled?: boolean
}

const MenuListComposition: FC<MenuListCompositionProps> = ({
  countryCode,
  error,
  countryKey,
  setCountryKey,
  value,
  name,
  componentDisabled = false,
}) => {
  const { t } = useTranslation()
  const { setValue, setError, clearErrors } = useFormContext()
  const classes = useStyles({} as FieldError)
  const [open, setOpen] = useState<boolean>(false)
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [search, setSearch] = useState<string>('')

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const changeCountry = useCallback(
    (key: string) => {
      // clear country if prefixCode !== currency country

      setCountryKey(key)
      // clearErrors(name)
      setOpen(false)
      if (checkPhone(prefixCode(key) + ' ' + value, key)) {
        // console.log('not Error')
        clearErrors(name)
        setValue(name, prefixCode(key) + ' ' + value, {
          shouldValidate: true,
          shouldDirty: true,
        })
      } else {
        value.length !== 0 &&
          setError(name, {
            type: 'manual',
            message: t('Validation-P11'),
          })
      }
    },
    [clearErrors, name, setCountryKey, setError, setValue, t, value],
  )

  const filteredCountries = useMemo(
    () => filter([...countriesArray], (c) => startsWith(toLower(c.label), toLower(search))),
    [search],
  )

  const handleSearch = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    return setSearch(event.target.value)
  }, [])

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])
  return (
    <Box className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={`${classes.countryFlag} ${error ? classes.errorFlag : ''}`}
        disabled={componentDisabled}
      >
        {countryCode && <FlagIcon code={countryCode} size={'lg'} />}
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </Button>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        // role={undefined}
        transition
        disablePortal
        className={classes.popper}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement !== 'bottom-start' ? 'left bottom' : 'left top' }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <List id="menu-list-grow">
                  <FormControl className={classes.searchField}>
                    <TextField
                      InputProps={{ startAdornment: <Search fontSize={'small'} /> }}
                      onChange={handleSearch}
                      value={search}
                      variant={'standard'}
                      autoFocus
                      placeholder={t('TalkToUs-P5')}
                    />
                  </FormControl>
                  {filteredCountries?.map((countryFilter: Country) => (
                    <ListItem
                      key={countryFilter.value}
                      onClick={() => changeCountry(countryFilter.value)}
                      button
                      className={classes.item}
                    >
                      <ListItemIcon>
                        <FlagIcon code={toLower(countryFilter.value)} size={'lg'} />
                      </ListItemIcon>
                      <ListItemText>
                        <Trans i18nKey={countryFilter.label} defaults={countryFilter.label} />
                      </ListItemText>
                      {countryKey === countryFilter.value && <Check fontSize={'small'} />}
                    </ListItem>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

const defaultRules = {} //{ required: true }

export const CountryPhoneSelect: FC<
  {
    name: string
    label: string
    countryDefault?: string
    defaultValue?: string | undefined
    componentDisabled?: boolean
  } & Partial<ControlledTextFieldProps>
> = ({
  name = '',
  label = '',
  countryDefault = DEFAULT_COUNTRY_CALLING_CODE,
  defaultValue = '',
  componentDisabled = false,
  rules,
  ...rest
}) => {
  const { t } = useTranslation()
  const { register, setValue, errors, setError, clearErrors, watch } = useFormContext()
  const error = errors ? errors[name] : null
  const classes = useStyles(error)
  const currentValue = watch(name)
  const fieldCountry = watch('country')

  useEffect(() => {
    register(name, { ...defaultRules, ...(rules ?? {}) })
  }, [register, name, rules])
  const [phoneNumberEdited, setPhoneNumberEdited] = useState<NationalNumber | string>('')
  const [countryKey, setCountryKey] = useState<string | undefined>(countryDefault)

  // check selected country
  useEffect(() => {
    if (fieldCountry) {
      setCountryKey(fieldCountry.toUpperCase())
    }
  }, [fieldCountry])

  useEffect(() => {
    const valueToSet = currentValue || defaultValue
    if (!!valueToSet) {
      try {
        const phoneDetails = parsePhoneNumber(valueToSet)
        setCountryKey(phoneDetails.country)
        setPhoneNumberEdited(phoneDetails.nationalNumber)
      } catch (e) {
        toast.error(t('Validation-P13'))
      }
    }
  }, [defaultValue, currentValue])

  const countryCode = useMemo(() => {
    const key = toLower(countryKey)
    return countries[(key as unknown) as keyof typeof countries]
  }, [countryKey])

  // function checkPhone(value: string, countryKey: string) {
  //   try {
  //     return parsePhoneNumber(value, countryKey as CountryCode | undefined).isValid()
  //   } catch (e) {
  //     return false
  //   }
  // }

  const handleChangePhoneNumber = useCallback<
    ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    (value) => {
      if (!!countryKey && countryKey.length > 0) {
        setPhoneNumberEdited(value.target.value)
        if (checkPhone(prefixCode(countryKey) + ' ' + value.target.value, countryKey)) {
          clearErrors(name)
        }
      } else {
        setError(name, {
          type: 'manual',
          message: t('Validation-P14'),
        })
      }
    },
    [clearErrors, countryKey, name, setError, t],
  )

  const handleBlurPhoneNumber = useCallback<
    ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    (value) => {
      if (!!countryKey && countryKey.length > 0) {
        if (!checkPhone(prefixCode(countryKey) + ' ' + value.target.value, countryKey)) {
          setError(name, {
            type: 'manual',
            message: t('Validation-P11'),
          })
        } else {
          clearErrors(name)
          setValue(name, prefixCode(countryKey) + ' ' + value.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
      } else {
        setError(name, {
          type: 'manual',
          message: t('Validation-P12'),
        })
      }
    },
    [countryKey, setError, name, t, clearErrors, setValue],
  )

  const renderMenuListComposition = useMemo(
    () => (
      <MenuListComposition
        countryCode={countryCode.value.toLowerCase()}
        countryKey={countryKey}
        setCountryKey={setCountryKey}
        value={phoneNumberEdited}
        name={name}
        error={!!error}
        componentDisabled={componentDisabled}
      />
    ),
    [countryCode.value, countryKey, phoneNumberEdited, name, error, componentDisabled],
  )

  return (
    <Box>
      {/*  <InputLabel className={classes.inputLabel}>*/}
      {/*    {toLower(label) === 'business phone'*/}
      {/*      ? t('Business phone', 'Business phone')*/}
      {/*      : t('phoneNumber', 'Phone Number')}*/}
      {/*  </InputLabel>*/}
      <Box className={classes.countryPhone}>
        <Box className={classes.basePart}>
          {renderMenuListComposition}
          <Box className={classes.inputWrap}>
            <TextField
              id="prefixCode"
              type="text"
              value={prefixCode(countryKey)}
              error={!!error}
              className={classes.phonePrefix}
              disabled
              aria-readonly={true}
            />
            <TextField
              id="phoneNumberEdited"
              type="text"
              value={phoneNumberEdited}
              onChange={handleChangePhoneNumber}
              onBlur={handleBlurPhoneNumber}
              error={!!error}
              className={classes.phoneInput}
              disabled={componentDisabled}
              name={name}
              label={label}
              {...rest}
            />
          </Box>
        </Box>

        {!!error && (
          <FormHelperText className={classes.redError}>{t(error.message)}</FormHelperText>
        )}
      </Box>
    </Box>
  )
}
