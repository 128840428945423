import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles, styled } from '@material-ui/core/styles'
import { AccordionDetails, AccordionSummary, AccordionProps, Accordion } from '@material-ui/core'
import { padStart } from 'lodash'

import { tagManagerHandler } from '../../utils'
import faqs from './faqContent'
import IconArrowDown from '../../assets/icons/icon-arrow-down.svg?react'
import IconLine from '../../assets/icons/line.svg?react'

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  pageTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
      fontSize: '2.25rem',
    },
  },
}))

const FAQAccordion = styled((props: AccordionProps) => (
  <Accordion elevation={0} square {...props} />
))(() => ({
  border: `0px`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

export const FaqPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const anchor = window.location.href.slice(window.location.href.indexOf('#') + 1)
  let firstLoad = true

  const setFirstLoad = (b: boolean) => (firstLoad = b)
  const getFirstLoad = () => firstLoad

  useEffect(() => {
    const element = document.getElementById(anchor)
    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY - 70
      setTimeout(
        () => {
          window.scrollTo({ top: y, behavior: 'smooth' })
        },
        getFirstLoad() ? 1000 : 100,
      )
      setFirstLoad(false)
    }
  }, [anchor, getFirstLoad, setFirstLoad])

  tagManagerHandler('PageFaq')

  return (
    <Box>
      <Container maxWidth="lg">
        <Box className={classes.row}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              className={classes.pageTitle}
            >
              {t('FAQ-H1')}
            </Typography>
          </Grid>

          <Grid item xs={12} justify={'center'}>
            {faqs.map((faq: any, i: number) => (
              <Box mb={10} key={i}>
                <Box mb={5}>
                  <Typography variant={'h3'}>{t(`FAQ-${faq.category}`)}</Typography>
                </Box>
                {faq.entries.map((entry: any, j: number) => (
                  <Box mb={3} key={`${i}-${j}`} id={`${`FAQ-${padStart(entry, 3, '0')}`}`}>
                    <FAQAccordion
                      defaultExpanded={anchor === `FAQ-${padStart(entry, 3, '0')}`}
                      style={{
                        backgroundColor: '#fafafa',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<IconArrowDown />}
                        style={{ marginLeft: '24px' }}
                      >
                        <Typography variant={'h4'}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `<a href="#${`FAQ-${padStart(entry, 3, '0')}`}">#</a> ${t(
                                `FAQ-Q${padStart(entry, 3, '0')}`,
                              )}`,
                            }}
                          />
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Container>
                          <Grid container>
                            <Grid item xs={12}>
                              <IconLine style={{ width: '100%', marginBottom: '20px' }} />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: t(`FAQ-A${padStart(entry, 3, '0')}`),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Container>
                      </AccordionDetails>
                    </FAQAccordion>
                  </Box>
                ))}
              </Box>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}
