import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import logo from '../../assets/images/logo.svg'
import { MainMenu } from '../MainMenu'
// import { LanguageSwitch } from '../LanguageSwitch'
import { Login } from '../Login'
import { Hidden, Typography } from '@material-ui/core'
import { Account } from '../Account'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router'
import { PATH_ROOT } from '../../constants'

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    boxShadow: '0px 0.5px 4px rgba(0, 0, 0, 0.15)',
  },
  toolBarWrapper: {
    backgroundColor: '#fff',
    position: 'fixed',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    zIndex: theme.zIndex.drawer + 1,
    top: '0px',
  },
  customizeToolbar: {
    width: '100%',
    minHeight: 64,
    padding: theme.spacing(0, 4),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbarScrolled: {
    zIndex: 1000,
    borderBottom: '1px solid rgba(0, 0, 0, 0.03)',
  },
  logo: {
    display: 'flex',
    flexShrink: 0,
    width: 155,
    height: 26,
    marginRight: 20,
  },
  logoImg: {
    width: '100%',
    height: '100%',
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
  },
  navItem: {
    whiteSpace: 'nowrap',
  },
  mobileHeaderTop: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  point: {
    cursor: 'pointer',
  },
  login: {
    display: 'block',
    lineHeight: '24px',
    marginTop: '15px',
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontSize: '0.875rem',
    textDecoration: 'none',
    color: 'black',
    whiteSpace: 'normal',
    fontWeight: 600,
    paddingLeft: 32,
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  banner: {
    width: '100%',
    maxWidth: '1000px',
    backgroundColor: '#000',
    padding: theme.spacing(2, 5),
    color: '#eee',
    '& a': {
      color: '#eee',
      fontWeight: 'normal',
    },
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(-10),
  },
  bannerMark: {
    float: 'left',
    width: '24px',
    textAlign: 'center',
    marginRight: '15px',
    borderRadius: '100%',
    border: '2px solid white',
  },
}))

export const Header: FC = () => {
  const [scrolled, setScrolled] = useState(false)
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const routerHomeHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  const listenScrollEvent = (_e: unknown) => {
    if (window.scrollY > 10) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
  })

  return (
    <Grid container justify={'center'}>
      <Box className={`${classes.toolBarWrapper} ${scrolled ? classes.toolbarScrolled : ''}`}>
        <Grid item xs={11} container justify={'center'}>
          <Toolbar className={classes.customizeToolbar}>
            <Box className={classes.logo}>
              <img
                src={logo}
                alt={'Klarpay AG'}
                className={classes.point}
                onClick={() => routerHomeHandler(PATH_ROOT)}
              />
            </Box>

            <MainMenu />

            <Hidden smDown>
              <Box display="flex" alignItems="center">
                {/* <LanguageSwitch /> */}
                <Login />
                <Account />
              </Box>
            </Hidden>
          </Toolbar>
        </Grid>
      </Box>
      <Box className={classes.banner}>
        <span className={classes.bannerMark}>
          <b>!</b>
        </span>
        <div dangerouslySetInnerHTML={{ __html: t('COMMON-Banner') }} />
      </Box>
    </Grid>
  )
}
