import React, { useState, useCallback, FC, useMemo } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from 'react-i18next'
import { makeStyles, Select } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import ArrowDown from '../../assets/icons/arrow-down.svg?react'

const useStyles = makeStyles((theme) => ({
  MuiListRoot: {
    background: 'red',
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 50,
    '& .MuiInputBase-root': {
      fontSize: '1rem',
      fontWeight: '600',
      [theme.breakpoints.down('sm')]: {
        '& .MuiTypography-body1': {
          fontWeight: 400,
          fontSize: '1rem',
          opacity: '65%',
        },
      },
    },
    '& .MuiSelect-select': {
      minWidth: '22px',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
    flexDirection: 'column',
  },
  menuItem: {
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: '1rem',
        opacity: '65%',
      },
    },
  },

  iconStyle: {
    position: 'absolute',
    right: '0',
    display: 'inline-block',
    width: 16,
    height: 16,
    pointerEvents: 'none',
  },
}))

export const LanguageSwitch: FC = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const [open, setOpen] = React.useState(false)

  const [lngId, setlngId] = React.useState(1)

  const parsedLng: string | null = localStorage.getItem('i18nextLng')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  useMemo(() => {
    switch (parsedLng) {
      case 'en-US':
        setlngId(1)
        break
      /*case 'de-DE':
        setlngId(2)
        break*/
      default:
        setlngId(1)
    }
  }, [parsedLng])

  const handleChange = useCallback(
    // eslint-disable-next-line
    (event: React.MouseEvent<any>) => {
      event.preventDefault()
      setAnchorEl(null)
      i18n.changeLanguage(event.currentTarget.dataset.lang)
    },
    [anchorEl, i18n],
  )

  const handleClose = useCallback(() => {
    setOpen(false)
    setAnchorEl(null)
  }, [anchorEl])

  const handleOpen = useCallback(
    // eslint-disable-next-line
    (event: any) => {
      setAnchorEl(event.currentTarget)
      setOpen(true)
    },
    [anchorEl],
  )

  return (
    <FormControl className={classes.formControl}>
      <Select
        disableUnderline={true}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        defaultValue={lngId}
        IconComponent={() => <ArrowDown className={classes.iconStyle} />}
      >
        <MenuItem
          data-lang="en-US"
          onClick={handleChange}
          value={1}
          classes={{ root: classes.menuItem }}
        >
          {t('languages.en')}
        </MenuItem>
        {/*<MenuItem*/}
        {/*data-lang="de-DE"*/}
        {/*onClick={handleChange}*/}
        {/*value={2}*/}
        {/*classes={{ root: classes.menuItem }}*/}
        {/*>*/}
        {/*{t('languages.de')}*/}
        {/*</MenuItem>*/}
        {/*<MenuItem*/}
        {/*data-lang="fr-FR"*/}
        {/*onClick={handleChange}*/}
        {/*value={3}*/}
        {/*classes={{ root: classes.menuItem }}*/}
        {/*>*/}
        {/*{t('languages.fr')}*/}
        {/*</MenuItem>*/}
        {/*<MenuItem*/}
        {/*data-lang="it-IT"*/}
        {/*onClick={handleChange}*/}
        {/*value={4}*/}
        {/*classes={{ root: classes.menuItem }}*/}
        {/*>*/}
        {/*{t('languages.it')}*/}
        {/*</MenuItem>*/}
      </Select>
    </FormControl>
  )
}
