import { FC, default as React, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import IconArrow from '../../assets/icons/icon-arrow.svg?react'
import CheckIcon from '../../assets/icons/icon-check-red.svg?react'
import QuoteLeftIcon from '../../assets/icons/icon-quote-left.svg?react'
import QuoteRightIcon from '../../assets/icons/icon-quote-right.svg?react'
import imgEntrepreneurAccounts1 from '../../assets/images/img-entrepreneur-accounts-1@2x.png'
import imgEntrepreneurAccounts3 from '../../assets/images/img-entrepreneur-accounts-3@2x.png'
import { tagManagerHandler } from '../../utils'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContentPref: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  iPhoneimg: {
    display: 'inline-block',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  icon: {
    justifyContent: 'center',
  },
  item2: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  greyBox: {
    backgroundColor: '#fafafa',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(1),
    paddingBotoom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(-12),
      marginRight: theme.spacing(-12),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
  },
  readMoreButton: {
    width: 216,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  footerNavColumns: {
    justifyContent: 'space-between',
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  footerNavColumnList: {
    display: 'block',

    MuiListItemButton: {
      padding: 0,
    },
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  subtitleBoxIcon: {
    body1: {
      fontSize: '1.125rem',
      fontWeight: 'normal',
    },
  },
  minW: {
    minWidth: '250px',
  },
  pad2x: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  pad2xBiased: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(0),
      marginBottom: theme.spacing(-2),
    },
  },
  pad8x: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      marginTop: theme.spacing(-6),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  pad64: {
    paddingTop: '64px',
    paddingBottom: '64px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  },
  pad6432: {
    paddingTop: '64px',
    paddingBottom: '32px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  },
  row: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  pageTitle: {
    marginTop: theme.spacing(8),
    fontSize: '3rem',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
      fontSize: '2.25rem',
    },
  },
  pageSubtitle: {
    fontSize: '2.75rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  pageEndTitle: {
    fontSize: '2rem',
    lineHeight: '50px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem',
      lineHeight: '30px',
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  features: {
    textAlign: 'center',
    marginBottom: theme.spacing(6),
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    minWidth: 250,
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  h3: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
  alignCenterXs: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
  },
  imgBottom: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
    },
  },
  listItem: {
    marginBottom: theme.spacing(3),
  },
}))

export const EntrepreneurAccountsPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const talkToUsOpenHandler = useCallback(() => {
    window.open('https://klarpay.com/talk-to-us')
  }, [])

  tagManagerHandler('PageEntrepreneurAccounts')

  return (
    <>
      <Container>
        <Box>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={classes.pageTitle}
            >
              {t('EntrepreneurAccounts-H1')}
            </Typography>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.greyBox)}>
          <Grid container spacing={2} className={classes.pad64}>
            <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
              <Box className={classes.pad8x}>
                <Typography
                  component="h1"
                  variant={'h1'}
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  className={clsx(classes.pageTitle, classes.pageSubtitle, classes.alignCenterXs)}
                >
                  {t('EntrepreneurAccounts-H2')}
                </Typography>
                <Typography>
                  <div dangerouslySetInnerHTML={{ __html: t('EntrepreneurAccounts-P1') }} />
                </Typography>
                <Box mt={6} className={clsx(classes.buttonContainer)}>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    endIcon={<IconArrow />}
                    onClick={talkToUsOpenHandler}
                    className={classes.getStartedButton}
                  >
                    {t('EntrepreneurAccounts-CTA1')}
                  </Button>
                </Box>
                <Box mt={6}>
                  <Typography>
                    <div
                      style={{ fontSize: '0.75rem' }}
                      dangerouslySetInnerHTML={{ __html: t('EntrepreneurAccounts-P2') }}
                    />
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
              <Box className={clsx(classes.pad2x)}>
                <Grid item xs={12} md={12}>
                  <CardMedia
                    component="img"
                    alt="Entrepreneur Accounts"
                    height="100%"
                    image={imgEntrepreneurAccounts1}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container>
          <Box className={clsx(classes.row, classes.extraMargin)}>
            <Grid container spacing={2}>
              <Grid className={classes.item2} item xs={12} container justify="flex-end">
                <Box className={classes.imgBottom}>
                  <Grid item xs={12} md={12}>
                    <Box mt={3}>
                      <QuoteLeftIcon style={{ marginRight: 'calc(100% - 24px)' }} />
                      <Box p={3}>
                        <Typography
                          component="h2"
                          variant={'h2'}
                          align="left"
                          color="textPrimary"
                          gutterBottom
                        >
                          {t('EntrepreneurAccounts-H3')}
                        </Typography>
                      </Box>
                      <QuoteRightIcon style={{ marginLeft: 'calc(100% - 80px)' }} />
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Box className={clsx(classes.row, classes.greyBox, classes.extraMargin)}>
          <Grid container spacing={5} className={classes.features} alignContent={'center'}>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('EntrepreneurAccounts-H4')}
              </Typography>
              <Typography>{t('EntrepreneurAccounts-P4')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('EntrepreneurAccounts-H5')}
              </Typography>
              <Typography>{t('EntrepreneurAccounts-P5')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('EntrepreneurAccounts-H6')}
              </Typography>
              <Typography>{t('EntrepreneurAccounts-P6')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('EntrepreneurAccounts-H7')}
              </Typography>
              <Typography>{t('EntrepreneurAccounts-P7')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('EntrepreneurAccounts-H8')}
              </Typography>
              <Typography>
                <div dangerouslySetInnerHTML={{ __html: t('EntrepreneurAccounts-P8') }} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('EntrepreneurAccounts-H9')}
              </Typography>
              <Typography>{t('EntrepreneurAccounts-P9')}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row)}>
          <Grid container spacing={2}>
            <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
              <Box className={classes.imgBottom}>
                <Grid item xs={12} md={12}>
                  <CardMedia
                    component="img"
                    alt=""
                    height="100%"
                    image={imgEntrepreneurAccounts3}
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
              <Box>
                <Typography
                  component="h2"
                  variant={'h2'}
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >
                  {t('EntrepreneurAccounts-H10')}
                </Typography>
                <Box mt={6}>
                  <Typography> {t('EntrepreneurAccounts-P10')} </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.greyBox)}>
          <Grid container spacing={2} className={classes.pad6432}>
            <Grid item xs={1} />
            <Grid item container xs={12} md={5}>
              <Grid container item xs={12} className={classes.listItem}>
                <Grid item xs={2} sm={1}>
                  <CheckIcon color="#ef2828" />
                </Grid>
                <Grid item xs={9} sm={11}>
                  <Typography
                    component="h3"
                    variant={'h3'}
                    align="left"
                    color="textPrimary"
                    gutterBottom
                    style={{ textAlign: 'left' }}
                  >
                    {t('EntrepreneurAccounts-P111')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.listItem}>
                <Grid item xs={2} sm={1}>
                  <CheckIcon />
                </Grid>
                <Grid item xs={9} sm={11}>
                  <Typography
                    component="h3"
                    variant={'h3'}
                    align="left"
                    color="textPrimary"
                    gutterBottom
                    style={{ textAlign: 'left' }}
                  >
                    {t('EntrepreneurAccounts-P113')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={4}>
              <Grid container item xs={12} className={classes.listItem}>
                <Grid item xs={2} sm={1}>
                  <CheckIcon />
                </Grid>
                <Grid item xs={9} sm={11}>
                  <Typography
                    component="h3"
                    variant={'h3'}
                    align="left"
                    color="textPrimary"
                    gutterBottom
                    style={{ textAlign: 'left' }}
                  >
                    {t('EntrepreneurAccounts-P112')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.listItem}>
                <Grid item xs={2} sm={1}>
                  <CheckIcon />
                </Grid>
                <Grid item xs={9} sm={11}>
                  <Typography
                    component="h3"
                    variant={'h3'}
                    align="left"
                    color="textPrimary"
                    gutterBottom
                    style={{ textAlign: 'left' }}
                  >
                    {t('EntrepreneurAccounts-P114')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Container>
          <Box className={clsx(classes.row, classes.extraMargin, classes.mrgbtm)}>
            <Grid container direction={'column'} alignItems="center">
              <Grid item xs={12} md={10}>
                <Typography
                  variant={'h1'}
                  align="center"
                  color="textPrimary"
                  gutterBottom
                  className={classes.rowTitle}
                >
                  {t('EntrepreneurAccounts-H12')}
                </Typography>
              </Grid>

              <Box mt={6} className={classes.buttonContainer}>
                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  className={classes.getStartedButton}
                  endIcon={<IconArrow />}
                  onClick={talkToUsOpenHandler}
                >
                  {t('EntrepreneurAccounts-CTA2')}
                </Button>
              </Box>
            </Grid>
          </Box>
        </Container>
      </Container>
    </>
  )
}
