import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import { ControlledTextFieldProps } from '../../../types'
import { filterOptions } from '../../../constants'

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    maxWidth: '100%',
    '& .MuiAutocomplete-popupIndicator': {
      pointerEvents: 'none',
    },
    '& .MuiInputBase-root': {
      paddingRight: 0,
    },
    '& .MuiAutocomplete-input ': {
      cursor: 'pointer',
      zIndex: 4,
    },
  },
  input: {},
  autocompleteListWrap: {
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      position: 'relative',
      paddingRight: 40,
      backgroundColor: 'transparent',
      '&::after': {
        position: 'absolute',
        top: 12,
        right: 16,
        content: '""',
        width: 14,
        height: 8,
        borderLeft: '2px solid',
        borderBottom: '2px solid',
        transform: 'rotate(-45deg)',
      },
    },
    '& .MuiAutocomplete-option[data-focus="true"]': {
      color: '#434343',
      backgroundColor: theme.palette.action.hover,
    },
  },
  readOnly: {
    '& .MuiFormLabel-root ': {
      zIndex: 1,
    },
    '& .MuiInput-root': {
      backgroundColor: '#F5F5F5',
      pointerEvents: 'none',
    },
    '& .MuiIconButton-root': {
      color: '#999999',
    },
    '& .MuiInputBase-root': {
      color: '#999999',
    },
  },
}))

interface FormAutocompleteSelectProps {
  name: string
  /*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
  data: Array<any>
  disableClearable?: boolean
  readOnly?: boolean
  defaultValue?: string | undefined
}
const defaultRules = {} //{ required: true }

export const FormAutocompleteSelect: React.FC<
  FormAutocompleteSelectProps & Partial<ControlledTextFieldProps>
> = ({
  name = '',
  disableClearable,
  data,
  shouldValidateParam,
  readOnly = false,
  defaultValue,
  rules,
  ...rest
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { errors, register, setValue, watch, clearErrors } = useFormContext()
  const error = errors ? errors[name || 'FormAutocompleteSelect'] : null
  const value = watch(name)

  useEffect(() => {
    register(name, { ...defaultRules, ...(rules ?? {}) })

    if (defaultValue) {
      setValue(name, defaultValue)
    }
  }, [register, name, rules, defaultValue])

  return (
    <Autocomplete
      key={value ? 'set' : 'unset'}
      className={`${classes.formField} ${readOnly ? classes.readOnly : ''}`}
      classes={{
        paper: classes.autocompleteListWrap,
      }}
      disableClearable={disableClearable || true}
      options={data}
      getOptionLabel={(option) => t(option.label)}
      getOptionDisabled={(option) => option.disabled}
      getOptionSelected={(option, value) => {
        return option.key === value.key
      }}
      onChange={(event, option) => {
        setValue(name, option ? option.key : null, {
          shouldValidate: shouldValidateParam ?? true,
          shouldDirty: true,
        })
        return clearErrors(name)
      }}
      value={data.find((v) => v.key === value)}
      disabled={readOnly}
      renderInput={(params) => (
        <TextField
          className={classes.input}
          name={name}
          {...params}
          error={!!error}
          helperText={error ? t(error.message) : null}
          {...rest}
          inputProps={{ ...rest.inputProps, ...params.inputProps, readOnly }}
        />
      )}
      filterOptions={filterOptions}
      popupIcon={<ExpandMoreIcon />}
    />
  )
}
