import React, { FC, useCallback } from 'react'
import { useForm, FormProvider, FormState } from 'react-hook-form'
import clsx from 'clsx'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import { FormControl, Hidden } from '@material-ui/core'

import cityContactUs from '../../assets/images/img-switzerland-7@2x.jpg'
import { tagManagerHandler } from '../../utils'
import IconArrow from '../../assets/icons/icon-arrow.svg?react'
import { CountryPhoneSelect, FormAutocompleteSelect, FormControlledTextField } from '../Common'
import { TalkToUsSchema } from '../../schemes/TalkToUsSchema'
import { emailEnquiryArray, PATH_MESSAGE_SENT } from '../../constants'
import { rxios } from '../../resources'
import { emailEnquiryTypes } from '@klarpay/enums'
import i18n from 'i18next'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
  },
  sendMessageButton: {
    width: 176,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    marginLeft: '-20px',
  },
  mobileButton: {
    width: '100%',
    marginLeft: 0,
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  row: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  extraMargin: {
    marginTop: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
    '& > div': {
      marginTop: theme.spacing(2),
    },
  },
  pageTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
      fontSize: '2.25rem',
    },
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '1.75rem',
    // },
  },
  rowTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '1.5rem',
    // },
  },
  contactList: {
    flexDirection: 'column',
    backgroundColor: '#f6f6f6',
    padding: theme.spacing(6, 6, 6, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 4),
    },
  },
  block: {
    '&.MuiGrid-item': {
      padding: theme.spacing(4, 0, 4, 1.5),
    },
  },
  secondMobileBlock: {
    padding: theme.spacing(6, 0, 3, 0),
  },
  listLeft: {
    flexDirection: 'column',
    padding: theme.spacing(6, 0, 6, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 4),
    },
  },
  mobileList: {
    padding: theme.spacing(1, 0, 0, 0),
  },
  contactItem: {
    fontSize: '1rem',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
  },
  contactItemLeft: {
    marginBottom: theme.spacing(5.5),
  },
  mobileHeader: {
    textAlign: 'center',
  },
  contactItemMain: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.24)',
    marginBottom: '32px!important',
    paddingBottom: theme.spacing(4),
  },
  contactTitle: {
    marginBottom: theme.spacing(3),
  },
  contactInfo: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  heroImage: {
    padding: theme.spacing(0, 3),
  },
  formControl: {
    width: '100%',
    height: theme.spacing(11.5),
    marginRight: theme.spacing(2),
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
    '& .MuiInputLabel-shrink': {
      display: 'none',
    },
  },
  formControlMobile: {
    height: theme.spacing(10),
  },
  shortField: {
    maxWidth: 320,
    marginRight: theme.spacing(4),
  },
  mobileField: {
    marginRight: theme.spacing(4),
  },
  longField: {
    maxWidth: 673,
    marginRight: theme.spacing(4),
  },
  twoItemRow: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  secondRow: {
    margin: theme.spacing(2, 4, 0, 0),
  },
}))

export const focusKeyPressNext = (e: React.KeyboardEvent, formState?: FormState<any>): void => {
  if (e.key === 'Enter') {
    if (
      formState &&
      (!formState.isValid ||
        !formState.isDirty ||
        (formState.isDirty && !isEmpty(formState.errors)))
    ) {
      e.preventDefault()
    }
    const forNextFocus = document.getElementById('forNextFocus')
    const inputs: HTMLInputElement[] = Array.from(
      forNextFocus?.querySelectorAll('input, select, textarea') ?? [],
    )
    const newArrayInputs = inputs.filter((item) => item.id !== 'prefixCode')
    const selectedInput = document.activeElement
    const indexOf = newArrayInputs.findIndex((item) => item.id === selectedInput?.id)
    if (indexOf + 1 < newArrayInputs.length) newArrayInputs[indexOf + 1].focus()
  }
}

export const TalkToUs: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(TalkToUsSchema),
  })

  const { reset } = methods
  const phoneNum = ''

  const onSubmit = useCallback(
    async (formData) => {
      try {
        rxios
          .post('inquiry', {
            fullName: formData?.fullName,
            email: formData?.email,
            companyName: formData?.companyName,
            phone: formData?.phone,
            inquiry: emailEnquiryTypes[formData?.inquiry as keyof typeof emailEnquiryTypes]?.value,
            message: formData?.message,
          })
          .subscribe(
            () => {
              toast.configure()
            },
            (error) => {
              toast.error(i18n.t('errorSendingEmail', 'Error sending email'))
              toast.error(error.message)
            },
            () => {
              reset()
              history.push(PATH_MESSAGE_SENT)
            },
          )
      } catch (e) {
        toast.error(i18n.t('errorSendingEmail', 'Error sending email'))
        toast.error(e.message)
      }
    },
    [history],
  )

  tagManagerHandler('PageTalkToUs')

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        id="forNextFocus"
        onKeyDown={focusKeyPressNext}
      >
        <Hidden smDown>
          <Container maxWidth="lg">
            <Box className={classes.row}>
              <Grid item xs={12} container justify={'center'}>
                <Typography
                  component="h1"
                  variant={'h1'}
                  align="center"
                  color="textPrimary"
                  className={classes.pageTitle}
                >
                  {t('TalkToUs')}
                </Typography>
              </Grid>

              <Grid item xs={12} container justify={'center'}>
                <Typography>{t('TalkToUs-H1')}</Typography>
              </Grid>
            </Box>

            <Box className={classes.row}>
              <Grid container spacing={8}>
                <Grid item xs={8} md={8} className={classes.block}>
                  <Grid container className={classes.listLeft}>
                    <Grid item xs={12} className={clsx(classes.contactItem)}>
                      <Typography
                        variant={'h3'}
                        className={(classes.contactTitle, classes.contactItemLeft)}
                      >
                        {t('TalkToUs-H3')}
                      </Typography>
                    </Grid>

                    <Box className={classes.twoItemRow}>
                      <FormControl className={clsx(classes.formControl, classes.shortField)}>
                        <FormControlledTextField
                          label={t('TalkToUs-H4')}
                          name="fullName"
                          type="text"
                          fullWidth
                          required={false}
                        />
                      </FormControl>

                      <FormControl className={clsx(classes.formControl, classes.shortField)}>
                        <FormControlledTextField
                          label={t('TalkToUs-H5')}
                          name="email"
                          type="text"
                          fullWidth
                          required={false}
                        />
                      </FormControl>
                    </Box>

                    <Box className={classes.twoItemRow}>
                      <FormControl
                        className={clsx(classes.formControl, classes.secondRow, classes.shortField)}
                      >
                        <FormControlledTextField
                          label={t('TalkToUs-H6')}
                          name="companyName"
                          type="text"
                          fullWidth
                          required={false}
                        />
                      </FormControl>

                      <FormControl className={clsx(classes.formControl, classes.shortField)}>
                        <CountryPhoneSelect
                          key={phoneNum}
                          name="phone"
                          label={t('TalkToUs-H7')}
                          defaultValue={phoneNum}
                        />
                      </FormControl>
                    </Box>

                    <Grid item className={clsx(classes.formControl, classes.longField)}>
                      <FormControl className={classes.formControl}>
                        <FormAutocompleteSelect
                          label={t('TalkToUs-H8')}
                          name="inquiry"
                          data={emailEnquiryArray}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item className={clsx(classes.formControl, classes.longField)}>
                      <FormControlledTextField
                        label={t('TalkToUs-H9')}
                        name="message"
                        type="text"
                        fullWidth
                        required={false}
                        multiline
                        maxRows={3}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={4} md={4} className={classes.block}>
                  <Grid container className={classes.contactList}>
                    <Grid
                      item
                      xs={12}
                      className={clsx(classes.contactItemMain, classes.contactItem)}
                    >
                      <Typography variant={'h3'} className={classes.contactTitle}>
                        {t('TalkToUs-H2')}
                      </Typography>

                      <Box display={'flex'} className={classes.contactInfo}>
                        <Box component="span" paddingRight={1}>
                          <PhoneOutlinedIcon />
                        </Box>

                        <Box component="span">
                          <Link href="tel:+41 41 552 0093">+41 41 552 0093</Link>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.contactItem}>
                      <Typography variant={'h4'} className={classes.contactTitle}>
                        {t('TalkToUs-P1')}
                      </Typography>

                      <Box display={'flex'} className={classes.contactInfo}>
                        <Box component="span" paddingRight={1}>
                          <EmailOutlinedIcon />
                        </Box>

                        <Box component="span">
                          <Link href="mailto:support@klarpay.com">support@klarpay.com</Link>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.contactItem}>
                      <Typography variant={'h4'} className={classes.contactTitle}>
                        {t('TalkToUs-P2')}
                      </Typography>

                      <Box display={'flex'} className={classes.contactInfo}>
                        <Box paddingRight={1}>
                          <EmailOutlinedIcon />
                        </Box>

                        <Box component="span">
                          <Link
                            href="mailto:complaint@klarpay.com
"
                          >
                            complaint@klarpay.com
                          </Link>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.contactItem}>
                      <Typography variant={'h4'} className={classes.contactTitle}>
                        {t('TalkToUs-P3')}
                      </Typography>

                      <Box display={'flex'} className={classes.contactInfo}>
                        <Box paddingRight={1}>
                          <EmailOutlinedIcon />
                        </Box>

                        <Box component="span">
                          <Link href="mailto:marketing@klarpay.com">marketing@klarpay.com</Link>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.contactItem}>
                      <Typography variant={'h4'} className={classes.contactTitle}>
                        {t('TalkToUs-P5')}
                      </Typography>

                      <Box display={'flex'} className={classes.contactInfo}>
                        <Box paddingRight={1}>
                          <EmailOutlinedIcon />
                        </Box>

                        <Box component="span">
                          <Link href="mailto:partnerships@klarpay.com">
                            partnerships@klarpay.com
                          </Link>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Button
                type="submit"
                className={classes.sendMessageButton}
                size="medium"
                variant="contained"
                endIcon={<IconArrow />}
              >
                {t('TalkToUs-P4')}
              </Button>
            </Box>
          </Container>
        </Hidden>

        <Hidden mdUp>
          <Container maxWidth="lg">
            <Box className={classes.row}>
              <Grid item xs={12} container justify={'center'}>
                <Typography
                  component="h1"
                  variant={'h1'}
                  align="center"
                  color="textPrimary"
                  className={classes.pageTitle}
                >
                  {t('TalkToUs')}
                </Typography>
              </Grid>

              <Grid item xs={12} container justify={'center'}>
                <Typography>{t('TalkToUs-H1')}</Typography>
              </Grid>
            </Box>

            <Box className={classes.row}>
              <Grid>
                <Grid item className={classes.block}>
                  <Grid container className={clsx(classes.mobileList, classes.listLeft)}>
                    <Grid item xs={12} className={clsx(classes.contactItem)}>
                      <Typography
                        variant={'h3'}
                        className={
                          (classes.contactTitle, classes.contactItemLeft, classes.mobileHeader)
                        }
                      >
                        {t('TalkToUs-H3')}
                      </Typography>
                    </Grid>

                    <FormControl
                      className={clsx(
                        classes.formControlMobile,
                        classes.formControl,
                        classes.mobileField,
                      )}
                    >
                      <FormControlledTextField
                        label={t('TalkToUs-H4')}
                        name="fullName"
                        type="text"
                        fullWidth
                        required={false}
                      />
                    </FormControl>

                    <FormControl
                      className={clsx(
                        classes.formControlMobile,
                        classes.formControl,
                        classes.mobileField,
                      )}
                    >
                      <FormControlledTextField
                        label={t('TalkToUs-H5')}
                        name="email"
                        type="text"
                        fullWidth
                        required={false}
                      />
                    </FormControl>

                    <FormControl
                      className={clsx(
                        classes.formControlMobile,
                        classes.formControl,
                        classes.mobileField,
                      )}
                    >
                      <FormControlledTextField
                        label={t('TalkToUs-H6')}
                        name="companyName"
                        type="text"
                        fullWidth
                        required={false}
                      />
                    </FormControl>

                    <FormControl
                      className={clsx(
                        classes.formControlMobile,
                        classes.formControl,
                        classes.mobileField,
                      )}
                    >
                      <CountryPhoneSelect
                        key={phoneNum}
                        name="phone"
                        label={t('TalkToUs-H7')}
                        defaultValue={phoneNum}
                      />
                    </FormControl>

                    <Grid
                      item
                      className={clsx(
                        classes.formControlMobile,
                        classes.formControl,
                        classes.mobileField,
                      )}
                    >
                      <FormControl className={classes.formControl}>
                        <FormAutocompleteSelect
                          label={t('TalkToUs-H8')}
                          name="inquiry"
                          data={emailEnquiryArray}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      className={clsx(
                        classes.formControlMobile,
                        classes.formControl,
                        classes.mobileField,
                      )}
                    >
                      <FormControlledTextField
                        label={t('TalkToUs-H9')}
                        name="message"
                        type="text"
                        fullWidth
                        required={false}
                        multiline
                        maxRows={3}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Button
                type="submit"
                className={clsx(classes.mobileButton, classes.sendMessageButton)}
                size="medium"
                variant="contained"
                endIcon={<IconArrow />}
              >
                {t('TalkToUs-P4')}
              </Button>

              <Grid>
                <Grid item className={(classes.block, classes.secondMobileBlock)}>
                  <Grid container className={(classes.mobileList, classes.contactList)}>
                    <Grid
                      item
                      xs={12}
                      className={clsx(classes.contactItemMain, classes.contactItem)}
                    >
                      <Typography variant={'h3'} className={classes.contactTitle}>
                        {t('TalkToUs-H2')}
                      </Typography>

                      <Box display={'flex'} className={classes.contactInfo}>
                        <Box component="span" paddingRight={1}>
                          <PhoneOutlinedIcon />
                        </Box>

                        <Box component="span">
                          <Link href="tel:+41 41 552 0093">+41 41 552 0093</Link>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.contactItem}>
                      <Typography variant={'h4'} className={classes.contactTitle}>
                        {t('TalkToUs-P1')}
                      </Typography>

                      <Box display={'flex'} className={classes.contactInfo}>
                        <Box component="span" paddingRight={1}>
                          <EmailOutlinedIcon />
                        </Box>

                        <Box component="span">
                          <Link href="mailto:support@klarpay.com">support@klarpay.com</Link>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.contactItem}>
                      <Typography variant={'h4'} className={classes.contactTitle}>
                        {t('TalkToUs-P2')}
                      </Typography>

                      <Box display={'flex'} className={classes.contactInfo}>
                        <Box paddingRight={1}>
                          <EmailOutlinedIcon />
                        </Box>

                        <Box component="span">
                          <Link href="mailto:complaint@klarpay.com">complaint@klarpay.com</Link>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.contactItem}>
                      <Typography variant={'h4'} className={classes.contactTitle}>
                        {t('TalkToUs-P3')}
                      </Typography>

                      <Box display={'flex'} className={classes.contactInfo}>
                        <Box paddingRight={1}>
                          <EmailOutlinedIcon />
                        </Box>

                        <Box component="span">
                          <Link href="mailto:marketing@klarpay.com">marketing@klarpay.com</Link>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.contactItem}>
                      <Typography variant={'h4'} className={classes.contactTitle}>
                        {t('TalkToUs-P5')}
                      </Typography>

                      <Box display={'flex'} className={classes.contactInfo}>
                        <Box paddingRight={1}>
                          <EmailOutlinedIcon />
                        </Box>

                        <Box component="span">
                          <Link href="mailto:partnerships@klarpay.com">
                            partnerships@klarpay.com
                          </Link>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Hidden>

        <Box className={clsx(classes.row, classes.extraMargin, classes.mrgbtm)}>
          <Box mt={8}>
            <CardMedia
              component="img"
              alt=""
              height="100%"
              className={classes.heroImage}
              image={cityContactUs}
            />
          </Box>
        </Box>
        <ToastContainer />
      </form>
    </FormProvider>
  )
}
