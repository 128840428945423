import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import Box from '@material-ui/core/Box'
import React, { FC, ReactNode, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  subMenuItemTextProp: {
    margin: 0,
    fontSize: '1.5rem',
    '& .MuiTypography-body1': {
      fontWeight: 700,
      fontSize: '1.25rem',
    },
  },
  subMenuItem: {
    width: 'auto',
    borderLeft: '4px solid transparent',
    '& .submenuItemInner': {
      display: 'flex',
      alignItems: 'top',
      paddingTop: theme.spacing(0),
      borderTop: '4px solid transparent',
      transition: 'border-top-color 0.2s, font-weight 0.2s',
      '& .subMenuItemInner >* ': {
        flex: '1 1 160',
        margin: 10,
      },
    },
    '&:hover': {
      borderLeft: 'solid 4px',

      backgroundColor: 'transparent',

      '& .MuiListItemIcon-root': {
        opacity: 1,
      },
    },
    '&:hover .subMenuItemInner': {
      borderLeftColor: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      borderLeftColor: theme.palette.secondary.main,
      '& .submenuItemInner': {
        borderLeftColor: theme.palette.secondary.main,
      },
      '& .MuiListItemIcon-root': {
        opacity: 1,
        color: theme.palette.secondary.main,
      },
      '& .MuiTypography-body1': {},
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  menuItemIcon: {
    minWidth: '2.5rem',
    opacity: 0.64,
    transition: 'opacity 0.2s',
    color: theme.palette.common.black,
  },
  subMenuItemText: {
    margin: 0,
    fontSize: '1.5rem',
    color: theme.palette.common.black,
  },
}))

export const MainSubMenuItem: FC<{
  primary: ReactNode
  path?: string | string[]
}> = ({ primary, path }) => {
  const match = useRouteMatch({ path: path || 'unknownPath', exact: true })
  const history = useHistory()
  const classes = useStyles()
  const redirect = useCallback(() => {
    if (path) {
      history.push(typeof path === 'string' ? path : path[0])
    }
  }, [history, path])

  return (
    <ListItem button selected={!!match} onClick={redirect} className={classes.subMenuItem}>
      <Box className="submenuItemInner">
        <ListItemText primary={primary} className={classes.subMenuItemTextProp} />
      </Box>
    </ListItem>
  )
}
