import { FC, default as React, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import { Hidden, MenuItem, MenuList } from '@material-ui/core'
import PayIcon from '../../assets/icons/icon-pay-where-you-do.svg?react'
import IconArrow from '../../assets/icons/icon-arrow.svg?react'
import MultiCurrencyAccount from '../../assets/icons/icon-multi-currency.svg?react'
import CompanyName from '../../assets/icons/icon-unique-iban.svg?react'
import UberTransparent from '../../assets/icons/icon-fees.svg?react'
import InstantSettlements from '../../assets/icons/icon-instant-settlements.svg?react'
import Secure from '../../assets/icons/icon-secure.svg?react'
import RealTimeMonitoring from '../../assets/icons/icon-real-time-monitoring.svg?react'
import TwoFA from '../../assets/icons/icon-2fa.svg?react'
import VirtualIbans from '../../assets/icons/icon-virtual-ibans.svg?react'
import Support from '../../assets/icons/icon-support.svg?react'
import imgLaptopIbans from '../../assets/images/img-virtual-iban@2x.png'
import imgVirtualIbans from '../../assets/images/img-virtual-iban-2@2x.png'
import imgVirtualIbans2 from '../../assets/images/img-virtual-iban-2-mobile@2x.png'
import { tagManagerHandler } from '../../utils'
import { PATH_TALKTOUS } from '../../constants'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContentPref: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  iPhoneimg: {
    display: 'inline-block',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  icon: {
    justifyContent: 'center',
  },
  item2: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  greyBox: {
    backgroundColor: '#fafafa',
    padding: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(-12),
      marginRight: theme.spacing(-12),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  readMoreButton: {
    width: 216,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  footerNavColumns: {
    justifyContent: 'space-between',
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  footerNavColumnList: {
    display: 'block',

    MuiListItemButton: {
      padding: 0,
    },
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  subtitleBoxIcon: {
    body1: {
      fontSize: '1.125rem',
      fontWeight: 'normal',
    },
  },
  menuList: {
    flexWrap: 'wrap',
    padding: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
    },

    '& .MuiMenuItem-root': {
      width: '50%',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2.5),
        width: '25%',
      },
      justifyContent: 'center',
      whiteSpace: 'normal',
    },

    '& .MuiListItem-button:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },

    '& .img:hover': { backgroundColor: theme.palette.secondary.main },

    '& .menuItemInner': {
      display: 'flex',
      alignItems: 'top',
      paddingTop: theme.spacing(0),
      borderTop: '8px solid transparent',
      transition: 'border-top-color 0.2s, font-weight 0.2s',
      position: 'relative',
      top: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',

      '& .MuiListItemIcon-root': {
        opacity: 1,
      },
    },
    '&:hover .menuItemInner': {
      backgroundColor: 'green',
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      background: theme.palette.secondary.main,
      '& .menuItemInner': {
        background: theme.palette.secondary.main,
      },
      '& .MuiListItemIcon-root': {
        opacity: 1,
        // color: theme.palette.secondary.main,
      },
      '& .MuiTypography-body1': {},
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  minW: {
    minWidth: '250px',
  },
  pad2x: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  pad2xBiased: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(0),
      marginBottom: theme.spacing(-2),
    },
  },
  pad8x: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  imgWrap: {
    textAlign: 'center',
  },
  row: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  rowSubtitle: {
    marginBottom: theme.spacing(6),
    color: '#555',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  pageTitle: {
    marginTop: theme.spacing(8),
    fontSize: '3rem',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
      fontSize: '2.25rem',
    },
  },
  pageSubtitle: {
    fontSize: '2.75rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  features: {
    textAlign: 'center',
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  h3: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
}))

export const VirtualIbanAccountsPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  tagManagerHandler('PageVirtualIbanAccounts')

  return (
    <>
      <Container>
        <Box>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={classes.pageTitle}
            >
              {t('VirtualIbanAccounts-H1')}
            </Typography>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.greyBox)}>
          <Grid container spacing={2}>
            <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
              <Box className={classes.pad8x}>
                <Typography>{t('VirtualIbanAccounts-P11')}</Typography>
                <Box mt={6} className={clsx(classes.buttonContainer)}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="secondary"
                    endIcon={<IconArrow />}
                    onClick={() => buttonHandler(PATH_TALKTOUS)}
                    className={classes.getStartedButton}
                  >
                    {t('COMMON-CTA-Consultation')}
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
              <Box className={clsx(classes.pad2x, classes.pad2xBiased)}>
                <Grid item xs={12} md={10}>
                  <CardMedia component="img" alt="" height="100%" image={imgLaptopIbans} />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row} mb={8}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={clsx(classes.pageTitle, classes.pageSubtitle)}
            >
              {t('VirtualIbanAccounts-H2')}
            </Typography>
          </Grid>
          <Grid item md={8} xs={12} container style={{ margin: 'auto', textAlign: 'center' }}>
            <Box mt={3}>
              <Typography>{t('VirtualIbanAccounts-P21')}</Typography>
            </Box>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container>
            <Hidden smDown>
              <Grid item xs={1}></Grid>
              <Grid item xs={12} md={10}>
                <CardMedia component="img" alt="" height="100%" image={imgVirtualIbans} />
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={12} md={10}>
                <CardMedia component="img" alt="" height="100%" image={imgVirtualIbans2} />
              </Grid>
            </Hidden>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <MenuList className={classes.menuList}>
                <MenuItem>
                  <Box component={'div'} width={'200px'}>
                    <Box display={'flex'} justifyContent={'center'}>
                      <MultiCurrencyAccount />
                    </Box>

                    <Box>
                      <Typography className={classes.subtitleBoxIcon} align={'center'}>
                        {t('VirtualIbanAccounts-H3')}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>

                <MenuItem>
                  <Box component={'div'} width={'200px'}>
                    <Box display={'flex'} justifyContent={'center'}>
                      <CompanyName style={{ padding: '1px' }} />
                    </Box>

                    <Box>
                      <Typography className={classes.subtitleBoxIcon} align={'center'}>
                        {t('VirtualIbanAccounts-H4')}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>

                <MenuItem>
                  <Box component={'div'} width={'200px'}>
                    <Box display={'flex'} justifyContent={'center'}>
                      <PayIcon />
                    </Box>

                    <Box>
                      <Typography className={classes.subtitleBoxIcon} align={'center'}>
                        {t('VirtualIbanAccounts-H5')}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>

                <MenuItem>
                  <Box component={'div'} width={'200px'}>
                    <Box display={'flex'} justifyContent={'center'}>
                      <UberTransparent />
                    </Box>

                    <Box>
                      <Typography className={classes.subtitleBoxIcon} align={'center'}>
                        {t('VirtualIbanAccounts-H6')}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
              </MenuList>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.greyBox)}>
          <Grid container spacing={3} className={classes.features} alignContent={'center'}>
            <Grid item xs={12} md={4}>
              <Box px={4} py={2}>
                <InstantSettlements />
              </Box>
              <Typography component="h3" variant={'h3'} className={classes.h3}>
                {t('VirtualIbanAccounts-H7')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box px={4} py={2}>
                <Secure />
              </Box>
              <Typography component="h3" variant={'h3'} className={classes.h3}>
                {t('VirtualIbanAccounts-H8')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box px={4} py={2}>
                <RealTimeMonitoring />
              </Box>
              <Typography component="h3" variant={'h3'} className={classes.h3}>
                {t('VirtualIbanAccounts-H9')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box px={4} py={2}>
                <TwoFA />
              </Box>
              <Typography component="h3" variant={'h3'} className={classes.h3}>
                {t('VirtualIbanAccounts-H10')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box px={4} py={2}>
                <VirtualIbans />
              </Box>
              <Typography component="h3" variant={'h3'} className={classes.h3}>
                {t('VirtualIbanAccounts-H11')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box px={4} py={2}>
                <Support />
              </Box>
              <Typography component="h3" variant={'h3'} className={classes.h3}>
                {t('VirtualIbanAccounts-H12')}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Container>
          <Box className={clsx(classes.row, classes.extraMargin, classes.mrgbtm)}>
            <Grid container direction={'column'} alignItems="center">
              <Grid item xs={12} md={10}>
                <Typography
                  variant={'h2'}
                  align="center"
                  color="textPrimary"
                  gutterBottom
                  className={classes.rowTitle}
                >
                  {t('COMMON-CTA-Title')}
                </Typography>
              </Grid>

              <Box mt={8} className={classes.buttonContainer}>
                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  className={classes.getStartedButton}
                  endIcon={<IconArrow />}
                  onClick={() => buttonHandler(PATH_TALKTOUS)}
                >
                  {t('COMMON-CTA-Consultation')}
                </Button>
              </Box>
            </Grid>
          </Box>
        </Container>
      </Container>
    </>
  )
}
