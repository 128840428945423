import React, { FC, useCallback } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import applePay from '../../assets/images/img-apple-pay@2x.png'
import googlePay from '../../assets/images/img-google-pay@2x.png'
import applePayLogo from '../../assets/images/apple-pay-logo@2x.png'
import googlePayLogo from '../../assets/images/google-pay-logo@2x.png'
import Container from '@material-ui/core/Container'
import { useHistory } from 'react-router'
import { PATH_APPLE_PAY, PATH_GOOGLE_PAY } from '../../constants'
import clsx from 'clsx'
import IconArrow from '../../assets/icons/icon-arrow.svg?react'
import { useTranslation } from 'react-i18next'
import { tagManagerHandler } from '../../utils'

const useStyles = makeStyles((theme) => ({
  heroImage: {
    maxWidth: '100%',
    backgroundRepeat: 'no-Repeat',
    backgroundPosition: 'center',
    color: '#fff',
    position: 'relative',
    backgroundSize: 'cover',
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
  },
  readMoreButton: {
    width: 216,
    height: 54,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  row: {
    marginTop: theme.spacing(9),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  greyBg: {
    backgroundColor: '#fafafa',
    padding: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
  },
  pageTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
      fontSize: '2.25rem',
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  item2: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  payLogo: {
    marginBottom: theme.spacing(4),
    height: 'auto',
    width: '100px',
    [theme.breakpoints.down('sm')]: {
      width: '70px',
      margin: '32px auto',
    },
  },
  payLogoLarge: {
    width: '250px',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
    },
  },
}))

export const CardsPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  tagManagerHandler('PageCards')

  return (
    <>
      <Container>
        <Box className={classes.row}>
          <Grid item xs={12} justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              className={classes.pageTitle}
              gutterBottom
            >
              {t('Card')}
            </Typography>
          </Grid>
          <Grid item xs={12} justify={'center'}>
            <Typography variant="subtitle1" align="center" component={'div'} gutterBottom>
              {t('Card-Subtitle')}
            </Typography>
          </Grid>
        </Box>

        <Box className={clsx(classes.row)}>
          <Typography variant="subtitle1" align="center" component={'div'} gutterBottom>
            {t('Card-P1')}
          </Typography>
        </Box>

        <Box className={clsx(classes.row, classes.greyBg)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} container>
              <Grid item xs={12} md={10}>
                <CardMedia component="img" alt="" height="100%" image={applePay} />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} className={classes.rowText}>
              <CardMedia
                component="img"
                alt={t('Card-H1')}
                className={classes.payLogo}
                image={applePayLogo}
              />
              <Typography className={classes.heroContentPrefHeader}>{t('Card-P11')}</Typography>
              <Button
                className={classes.readMoreButton}
                size="medium"
                variant="contained"
                endIcon={<IconArrow />}
                onClick={() => buttonHandler(PATH_APPLE_PAY)}
              >
                {t('Usecases-P22')}
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.greyBg)}>
          <Grid container spacing={2}>
            <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
              <CardMedia
                component="img"
                alt={t('Card-H2')}
                className={clsx(classes.payLogo, classes.payLogoLarge)}
                image={googlePayLogo}
              />
              <Typography className={classes.heroContentPrefHeader}>{t('Card-P21')}</Typography>
              <Button
                className={classes.readMoreButton}
                size="medium"
                variant="contained"
                onClick={() => buttonHandler(PATH_GOOGLE_PAY)}
                endIcon={<IconArrow />}
              >
                {t('Usecases-P32')}
              </Button>
            </Grid>

            <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
              <Grid item xs={12} md={10}>
                <CardMedia component="img" alt="" height="100%" image={googlePay} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
