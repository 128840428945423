import { FC, default as React, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useForm, FormProvider, FormState } from 'react-hook-form'
import clsx from 'clsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast, ToastContainer } from 'react-toastify'
import i18n from 'i18next'
import { isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { CardMedia, Hidden } from '@material-ui/core'
import IconArrow from '../../assets/icons/icon-arrow.svg?react'
import IconRightArrow from '../../assets/icons/icon-arrow-long-right.svg?react'
import IconDownArrow from '../../assets/icons/icon-arrow-long-down.svg?react'
import FormIcon from '../../assets/icons/icon-form.svg?react'
import VerifiedIcon from '../../assets/icons/icon-verified.svg?react'
import EarnIcon from '../../assets/icons/icon-earn.svg?react'
import UnderstandingIcon from '../../assets/icons/icon-understanding.svg?react'
import FinmaIcon from '../../assets/icons/icon-finma.svg?react'
import ApiIcon from '../../assets/icons/icon-api.svg?react'
import SupportIcon from '../../assets/icons/icon-support.svg?react'
import CheckIcon from '../../assets/icons/icon-check.svg?react'
import QuoteLeftIcon from '../../assets/icons/icon-quote-left.svg?react'
import QuoteRightIcon from '../../assets/icons/icon-quote-right.svg?react'
import imgPartnership from '../../assets/images/img-partnership@2x.png'
import imgQuote from '../../assets/images/img-quote-ceo@2x.svg'
import { PartnerhipSchema } from '../../schemes/PartnershipSchema'
import { PATH_MESSAGE_SENT } from '../../constants'
import { rxios } from '../../resources'
import { emailEnquiryTypes } from '@klarpay/enums'
import { tagManagerHandler } from '../../utils'

const useStyles = makeStyles((theme) => ({
  '.MuiIconButton-label': {
    marginTop: '-26px',
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContentPref: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  iPhoneimg: {
    display: 'inline-block',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  icon: {
    justifyContent: 'center',
  },
  item2: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  greyBox: {
    backgroundColor: '#fafafa',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(-12),
      marginRight: theme.spacing(-12),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
  },
  readMoreButton: {
    width: 216,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  footerNavColumns: {
    justifyContent: 'space-between',
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  footerNavColumnList: {
    display: 'block',

    MuiListItemButton: {
      padding: 0,
    },
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  subtitleBoxIcon: {
    body1: {
      fontSize: '1.125rem',
      fontWeight: 'normal',
    },
  },
  minW: {
    minWidth: '250px',
  },
  pad2x: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  pad2xBiased: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(0),
      marginBottom: theme.spacing(-2),
    },
  },
  pad8x: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginTop: theme.spacing(-8),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  row: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  pageTitle: {
    marginTop: theme.spacing(8),
    fontSize: '3rem',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
      fontSize: '2.25rem',
    },
  },
  pageSubtitle: {
    fontSize: '2.75rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  pageEndTitle: {
    fontSize: '2rem',
    lineHeight: '50px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem',
      lineHeight: '30px',
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  features: {
    textAlign: 'center',
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  h3: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
  alignCenterXs: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
  },
  imgBottom: {
    padding: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
    },
  },
  listItem: {
    marginBottom: theme.spacing(3),
  },
  lastItem: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(-5),
    },
  },
  block: {
    '&.MuiGrid-item': {
      padding: theme.spacing(4, 0, 4, 1.5),
    },
  },
  secondMobileBlock: {
    padding: theme.spacing(6, 0, 3, 0),
  },
  listLeft: {
    flexDirection: 'column',
    padding: theme.spacing(6, 0, 6, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 4),
    },
  },
  mobileList: {
    padding: theme.spacing(1, 0, 0, 0),
  },
  contactItem: {
    fontSize: '1rem',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
  },
  contactItemLeft: {
    marginBottom: theme.spacing(5.5),
  },
  mobileHeader: {
    textAlign: 'center',
  },
  contactItemMain: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.24)',
    marginBottom: '32px!important',
    paddingBottom: theme.spacing(4),
  },
  contactTitle: {
    marginBottom: theme.spacing(3),
  },
  contactInfo: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  heroImage: {
    padding: theme.spacing(0, 3),
  },
  formControl: {
    width: '100%',
    height: theme.spacing(11.5),
    marginRight: theme.spacing(2),
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
    '& .MuiInputLabel-shrink': {
      display: 'none',
    },
  },
  formControlMobile: {
    height: theme.spacing(10),
  },
  shortField: {
    maxWidth: 320,
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  mobileField: {
    marginRight: theme.spacing(4),
  },
  longField: {
    maxWidth: 673,
    marginRight: theme.spacing(4),
  },
  twoItemRow: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  secondRow: {
    margin: theme.spacing(2, 4, 0, 0),
  },
  sendMessageButton: {
    width: 176,
    fontWeight: 700,
    boxShadow: 'none',
  },
  mobileButton: {
    width: '100%',
    marginLeft: 0,
  },
  rightArrow: {
    marginTop: theme.spacing(3),
    marginLeft: '4%',
    position: 'absolute',
  },
  downArrow: {
    margin: '20px auto -40px',
  },
  backgroundImageContainer: {
    background: `url(${imgPartnership}) no-repeat`,
    padding: theme.spacing(12),
  },
}))

export const focusKeyPressNextPartnerships = (
  e: React.KeyboardEvent,
  formState?: FormState<any>,
): void => {
  if (e.key === 'Enter') {
    if (
      formState &&
      (!formState.isValid ||
        !formState.isDirty ||
        (formState.isDirty && !isEmpty(formState.errors)))
    ) {
      e.preventDefault()
    }
    const forNextFocus = document.getElementById('forNextFocus')
    const inputs: HTMLInputElement[] = Array.from(
      forNextFocus?.querySelectorAll('input, select, textarea') ?? [],
    )
    const newArrayInputs = inputs.filter((item) => item.id !== 'prefixCode')
    const selectedInput = document.activeElement
    const indexOf = newArrayInputs.findIndex((item) => item.id === selectedInput?.id)
    if (indexOf + 1 < newArrayInputs.length) newArrayInputs[indexOf + 1].focus()
  }
}

export const PartnershipsPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState<boolean>(false)

  const privacyPolicyCheckBox = useCallback(() => {
    setPrivacyPolicyChecked((previous) => !previous)
  }, [privacyPolicyChecked])

  const handleGoToSignup = () => {
    window.location.href = 'https://dashboard.klarpay.com/signup/partner/'
  }

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(PartnerhipSchema),
  })

  const { reset } = methods
  const country = ''

  const onSubmit = useCallback(
    async (formData) => {
      try {
        rxios
          .post('inquiry', {
            fullName: formData?.fullName,
            email: formData?.email,
            companyName: formData?.companyName,
            country: formData?.country,
            inquiry: emailEnquiryTypes.introducingPartner.value,
            message: 'Privacy policy accepted',
          })
          .subscribe(
            () => {
              toast.configure()
            },
            (error) => {
              toast.error(i18n.t('errorSendingEmail', 'Error submitting the inquiry'))
              toast.error(error.message)
            },
            () => {
              reset()
              history.push(PATH_MESSAGE_SENT)
            },
          )
      } catch (e) {
        toast.error(i18n.t('errorSendingEmail', 'Error submitting the inquiry'))
        toast.error(e.message)
      }
    },
    [history],
  )

  tagManagerHandler('PagePartnerships')

  return (
    <>
      <Container>
        <Box className={clsx(classes.row, classes.greyBox, classes.backgroundImageContainer)}>
          <Grid container spacing={2}>
            <Grid className={clsx(classes.item2, classes.rowText)} item xs={12} md={6}>
              <Box className={classes.pad8x}>
                <Typography
                  component="h1"
                  variant={'h1'}
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  className={clsx(classes.pageTitle, classes.pageSubtitle, classes.alignCenterXs)}
                >
                  {t('Partnerships-H1')}
                </Typography>
                <Typography>{t('Partnerships-P11')}</Typography>
                <Box mt={6} className={clsx(classes.buttonContainer)}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="secondary"
                    endIcon={<IconArrow />}
                    onClick={handleGoToSignup}
                    className={classes.getStartedButton}
                  >
                    {t('Partnerships-P12')}
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid className={classes.item3} item xs={12} md={6} container justify="flex-end">
              <Box className={clsx(classes.pad2x)}>
                <Grid item xs={12} md={12}>
                  {/* <CardMedia component="img" alt="" height="100%" image={imgPartnership} /> */}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row} mb={8}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={clsx(classes.pageTitle, classes.pageSubtitle)}
            >
              {t('Partnerships-H2')}
            </Typography>
          </Grid>
          <Grid item md={8} xs={11} container style={{ margin: 'auto', textAlign: 'center' }}>
            <Box mt={3}>
              <Typography>{t('Partnerships-P21')}</Typography>
            </Box>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container spacing={5} className={classes.features} alignContent={'center'}>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <FormIcon />
                <Hidden smDown>
                  <IconRightArrow className={classes.rightArrow} />
                </Hidden>
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('Partnerships-H3')}
              </Typography>
              <Typography>
                <div dangerouslySetInnerHTML={{ __html: t('Partnerships-P3') }} />
              </Typography>
              <Hidden mdUp>
                <IconDownArrow className={classes.downArrow} />
              </Hidden>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <VerifiedIcon />
                <Hidden smDown>
                  <IconRightArrow className={classes.rightArrow} />
                </Hidden>
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('Partnerships-H4')}
              </Typography>
              <Typography>{t('Partnerships-P4')}</Typography>
              <Hidden mdUp>
                <IconDownArrow className={classes.downArrow} />
              </Hidden>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <EarnIcon />
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('Partnerships-H5')}
              </Typography>
              <Typography>{t('Partnerships-P5')}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.greyBox)}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={clsx(classes.pageTitle, classes.pageSubtitle)}
            >
              {t('Partnerships-H6')}
            </Typography>
          </Grid>
          <Grid container spacing={5} className={classes.features} alignContent={'center'}>
            <Grid item xs={12} md={3}>
              <Box p={4}>
                <UnderstandingIcon />
              </Box>
              <Typography>{t('Partnerships-P61')}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box p={4}>
                <FinmaIcon />
              </Box>
              <Typography>{t('Partnerships-P62')}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box p={4}>
                <ApiIcon />
              </Box>
              <Typography>{t('Partnerships-P63')}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box p={4}>
                <SupportIcon />
              </Box>
              <Typography>{t('Partnerships-P64')}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row} mb={8}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={clsx(classes.pageTitle, classes.pageSubtitle)}
            >
              {t('Partnerships-H7')}
            </Typography>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container spacing={2}>
            <Grid item container xs={12} md={6}>
              <Grid container item xs={12} className={classes.listItem}>
                <Grid item xs={2} sm={1}>
                  <CheckIcon />
                </Grid>
                <Grid item xs={9} sm={11}>
                  <Typography align="left">{t('Partnerships-P71')}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.listItem}>
                <Grid item xs={2} sm={1}>
                  <CheckIcon />
                </Grid>
                <Grid item xs={9} sm={11}>
                  <Typography align="left">{t('Partnerships-P72')}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.listItem}>
                <Grid item xs={2} sm={1}>
                  <CheckIcon />
                </Grid>
                <Grid item xs={9} sm={11}>
                  <Typography align="left">{t('Partnerships-P73')}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid container item xs={12} className={classes.listItem}>
                <Grid item xs={2} sm={1}>
                  <CheckIcon />
                </Grid>
                <Grid item xs={9} sm={11}>
                  <Typography align="left">{t('Partnerships-P74')}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={clsx(classes.listItem, classes.lastItem)}>
                <Grid item xs={2} sm={1}>
                  <CheckIcon />
                </Grid>
                <Grid item xs={9} sm={11}>
                  <Typography align="left">{t('Partnerships-P75')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.row, classes.greyBox)}>
          <Box mb={8}>
            <Grid item xs={12} container justify={'center'}>
              <Typography
                component="h1"
                variant={'h1'}
                align="center"
                color="textPrimary"
                gutterBottom
                className={clsx(classes.pageTitle, classes.pageSubtitle)}
              >
                {t('Partnerships-H8')}
              </Typography>
            </Grid>
          </Box>
          <Grid container spacing={5} className={classes.features} alignContent={'center'}>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('Partnerships-H9')}
              </Typography>
              <Typography>{t('Partnerships-P9')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('Partnerships-H10')}
              </Typography>
              <Typography>{t('Partnerships-P101')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('Partnerships-H11')}
              </Typography>
              <Typography>{t('Partnerships-P111')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('Partnerships-H12')}
              </Typography>
              <Typography>{t('Partnerships-P121')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('Partnerships-H13')}
              </Typography>
              <Typography>{t('Partnerships-P131')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h3" variant={'h3'}>
                {t('Partnerships-H14')}
              </Typography>
              <Typography>{t('Partnerships-P141')}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row} mb={12}>
          <Grid container>
            <Hidden smDown>
              <Grid item xs={3}>
                <Box pt={3} style={{ width: '60%', margin: 'auto' }}>
                  <CardMedia
                    component="img"
                    alt=""
                    image={imgQuote}
                    style={{ borderRadius: '100%', border: '2px solid #EF2828', padding: '1px' }}
                  />
                </Box>
                <Box mt={3}>
                  <Typography align="center">
                    <div dangerouslySetInnerHTML={{ __html: t('Partnerships-PQA') }} />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={8}>
                <Box mt={3}>
                  <QuoteLeftIcon />
                  <Box p={3}>
                    <Typography align="left">
                      <div dangerouslySetInnerHTML={{ __html: t('Partnerships-PQ') }} />
                    </Typography>
                  </Box>
                  <QuoteRightIcon style={{ marginLeft: 'calc(100% - 24px)' }} />
                </Box>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item xs={12}>
                <Box style={{ width: '50%', margin: '10px auto 10px' }}>
                  <CardMedia
                    component="img"
                    alt=""
                    image={imgQuote}
                    style={{ borderRadius: '100%', border: '2px solid #EF2828', padding: '1px' }}
                  />
                </Box>
                <Box mt={3} mb={6}>
                  <Typography align="center">
                    <div dangerouslySetInnerHTML={{ __html: t('Partnerships-PQA') }} />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <QuoteLeftIcon style={{ marginRight: '100%', marginBottom: '12px' }} />
                <Box>
                  <Typography align="center">
                    <div dangerouslySetInnerHTML={{ __html: t('Partnerships-PQ') }} />
                  </Typography>
                </Box>
                <QuoteRightIcon style={{ marginLeft: 'calc(100% - 24px)', marginTop: '12px' }} />
              </Grid>
            </Hidden>
          </Grid>
          <Box id="form" />
        </Box>
        <Box className={clsx(classes.greyBox, classes.mrgbtm)}>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              id="forNextFocus"
              onKeyDown={focusKeyPressNextPartnerships}
            >
              <Hidden smDown>
                <Container maxWidth="lg">
                  <Box className={classes.row}>
                    <Grid item xs={12} container justify={'center'}>
                      <Typography
                        component="h1"
                        variant={'h1'}
                        align="center"
                        color="textPrimary"
                        className={classes.pageTitle}
                      >
                        {t('Partnerships-H15')}
                      </Typography>
                      <Box mt={6} mb={10} className={clsx(classes.buttonContainer)}>
                        <Button
                          size="medium"
                          variant="contained"
                          color="secondary"
                          endIcon={<IconArrow />}
                          onClick={handleGoToSignup}
                          className={classes.getStartedButton}
                        >
                          {t('Partnerships-P156')}
                        </Button>
                      </Box>
                    </Grid>
                  </Box>
                </Container>
              </Hidden>
              <ToastContainer />
            </form>
          </FormProvider>
        </Box>
      </Container>
    </>
  )
}
