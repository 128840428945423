import * as React from 'react'
import { render } from 'react-dom'
import App from './App'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { defaultTheme } from './theme/default'
import './i18n'
import './index.css'
import { BrowserRouter } from 'react-router-dom'

render(
  <>
    <React.Suspense fallback={() => null}>
      <CssBaseline />
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </React.Suspense>
  </>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
